<template>
  <div class="trainingList">
    <div v-for="(item,index) in viewList" :key="index" class="viewItem">
      <a :href="'/resourceDetail?tabValue=trainings&itemTitle='+item.title+'&id='+item.id">
        <img :src="item.thumbnail" class="thumbnail">
       
        <img src="@a/images/resourceCenter/play.png" class="play">
        <div class="viewDes" :title="item.title">
          {{ item.title }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'trainingList',
  props: {
    viewList:{
      type:Array,
      default: () => [],
    },
  },
  components: {
 
  },
  data() {
    return {
    }
  },
  methods: {
    handerSlider(value){
      this.actived = value
    }
  },
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.trainingList{
  width: 1200px;
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  .viewItem{
    width: 270px;
    margin-right: 27px;
    margin-bottom: 50px;
    position: relative;
    img.thumbnail{
      display: block;
      width: 270px;
      height: 152px;
      border-radius: 15px;
    }
    img.play{
      position: absolute;
      right: 12px;
      top: 99px;
      width: 40px;
      height: 40px;
    }
    .viewDes{
      font-family: AlibabaPuHuiTiM;
      font-size: 20px;
      line-height: 27px;
      text-align: left;
      padding-left: 16px;
      margin-top: 20px;
      color: rgba(0,0,0,0.8);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span{
        color: #FB9716;
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .trainingList{
    width: auto;
    display: block;
    margin: 0 auto;
    flex-wrap: wrap;
    margin: 0 20px;
  }
  .trainingList .viewItem {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .trainingList .viewItem img.thumbnail{
    display: block;
    width: 100%;
    height: auto;
    min-height: 160px;
  }
  .trainingList .viewItem img.play{
    position: absolute;
    right: 12px;
    top: 129px;
    width: 40px;
    height: 40px;
  }
  .trainingList .viewItem .viewDes{
    margin-top: 10px;
    font-size: 18px;
  }

}

</style>
<style lang="scss">

</style>

<template>
  <div class="ResourceCenter">
    <Header :activedUrl="activedUrl" :background="background"></Header>
    <div class="resouceSearch resouceSearch-index">

      <div class="search" :class="{'fixSarch':scrollTop > 0}">
        <div class="searc-box">
          <span class="search-tit">资源中心</span>
          <span class="search-inline" @click="handleEnter">
            <v-text-field
              class="search-texts"
              v-model="searchValue"
              prepend-inner-icon="mdi-magnify"
              placeholder="搜索"
              clearable
              filled
              rounded
            ></v-text-field>
          </span>
        </div>
        
      </div>

      <div class="bigTitle" title="教学说明">内容出海知识库</div>
      <div class="search-text">
        加入小五出海通，获取
        <span>独家海外市场报告、爆款案例解析、平台算法秘籍</span>
        等资源，助你在全球内容市场中脱颖而出！
      </div>

      <div class="critical-main">

        <div class="critical-left" @click="toDetail()">
          <img :src="knowCurrent.thumbnail" v-show="knowCurrent.thumbnail"> 
          <div class="left-text">{{ knowCurrent.title}}</div>
        </div>
        <div class="critical-item " >
          <img src="@a/images/header/title.png">
          <div class="cri-box">
            <div class="cri-text" v-for="(row,i) in currentItem" :key="i">
              <a :href="'/resourceDetail?tabValue=numbs&itemTitle='+row.title+'&id='+row.id">{{row.title }}</a>
            </div>
          </div>
          
          <div class="cri-page">
            <span v-for="(item,i) in pageLength" :key="i"  @click="hangePage(i)" :class="{'activeYellow':item==pageIndex}" v-show="i < 6" >{{ item+1 }}</span>
          </div>
          <div class="page-down" title="常见问题" @click="handerProble">
            {{ isOpen ? '收起':'展开全部' }}
            <v-icon
              v-if="isOpen"
              size="30"
              class="me-2"
              color="primary"
            >mdi-chevron-up
            </v-icon>
            <v-icon
              v-else
              size="30"
              class="me-2"
              color="primary"
            >mdi-chevron-down
            </v-icon>
          </div>

        </div>
      </div>
      

    </div>
    <div class="bigTitle traningTit" title="培训活动" >培训活动</div>

    <TraningList :viewList="viewList"></TraningList>

    <Foot></Foot>
    <v-overlay :value="loading">
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
  </div>
</template>

<script>
import Header from '@v/header/index'
import Foot from '@v/foot/index'
import TraningList from './components/trainingList.vue'
import { websitePage } from '@/api'
import { toastInfo } from '@/utils/prompt'

export default {
  name: 'ResourceCenter',
  props: {},
  components: {
    Header, Foot,TraningList
  },
  data() {
    return {
      activedUrl:'resourceCenter',
      background:'resourceCenter',
      searchValue:'',
      criticalData:[],

      scrollTop:0,
      pageIndex:0,
      pageLength:[],
      knowCurrent:{
        // thumbnail:require('@a/images/resourceCenter/left.png')
      },
      viewList:[],
      loading:false,
      isOpen:false,
      isH5:false,
      dataIndex:4
    }
  },
  computed: {
    currentItem(){
      if(!this.isH5){
        let start= this.pageIndex*4
        let end=this.pageIndex*4 +4
        return this.criticalData.slice(start,end)
      } else {
        let start= this.pageIndex* this.dataIndex
        let end=this.pageIndex*4 + this.dataIndex
        return this.criticalData.slice(start,end)
      }
      
    }
  },
  methods: {
    textLength(value){
      if(value.length >20 ){
        toastInfo('输入搜索内容,不可大于20字符！')
        this.searchValue = value.slice(0,20)
      }
    },
    getList() {
      this.loading = true
      websitePage()
        .then(({ data }) => {
          this.loading = false
          const {knowledge_base,train_activity } = data
          console.log('data:',data)
          this.criticalData = knowledge_base.filter(item => item.isFirst !==1)
          this.knowCurrent = knowledge_base.filter(item => item.isFirst==1)[0] 
          this.pageLength = [...Array(Math.ceil(knowledge_base.length/4)).keys()];
          this.viewList =train_activity
          
        })
        .catch(error => error)
    },
   
    hangePage(value){
      this.pageIndex = value
    },
    handerProble(){
      this.isH5 = true
      this.isOpen= this.isOpen ? false : true
      this.dataIndex = this.isOpen ? this.criticalData.length : 4
    },
    checkPosition() {
      this.scrollTop = document.documentElement
        ? document.documentElement.scrollTop
        : document.body.scrollTop
    },
    handleEnter(){
      window.location.href = '/resourceSearch'
    },
    toDetail(){
      if(this.knowCurrent.id ){
        window.location.href = '/resourceDetail?tabValue=numbs&itemTitle='+this.knowCurrent.title+'&id='+this.knowCurrent.id;
      }
    }

  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.checkPosition()
    })
    this.getList()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  watch: {},
  
  filters: {},
}
</script>

<style scoped lang="scss">
.ResourceCenter{
  overflow-x: scroll;
}

.resouceSearch{
  padding-top: 150px;
  background-image:  url('@a/images/resourceCenter/resourceCenter.png');
  background-size: 100% 100%;
  position: relative;
 
  
  .search{
    .searc-box{
      width: 1200px;
      margin: 0 auto;
      .search-tit{
        display: none;
      }
    }
    text-align: center;
    span.search-inline{
      display: inline-block;
      width: 450px;
      cursor: pointer;
      // padding-top: 4px;
    }
   
  }
  .fixSarch{
    position: fixed;
    z-index: 1;
    left: 0px;
    top: 80px;
    background: rgba(255,255,255,1);
    box-shadow: inset 0px 1px 0px 0px rgba(210,210,210,0.16), inset 0px -1px 0px 0px rgba(193,193,193,0.2);
    width: 100%;
    padding-top: 10px;
    transition: all 0.5s;
    .searc-box{
      display: flex;
      justify-content: space-between;
      .search-tit{
        display: inline-block;
        line-height: 60px;
        font-family: AlibabaPuHuiTiM;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.8);
      }
    }

  }
  .bigTitle{
    margin-top: 50px;
    margin-bottom: 4px;
  }
  .search-text{
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0,0,0,0.4);
    line-height: 22px;
    text-align: center;
    margin-bottom: 60px;
    span{
      color: #FB9716;
    }
  }
}


.critical-main{
  display: flex;
  justify-content:space-between;
  width: 1160px;
  margin: 0 auto;
  .critical-left{
    width: 720px;
    height: 360px;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    img{
      display: block;
      width: 720px;
      height: 360px;
      transition: all 0.5s;
    }
    .left-text{
      position: absolute;
      width: 100%;
      left: 0px;
      bottom: 0px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 20px;
      color: #FFFFFF;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 81px;
      line-height: 81px;
      padding-left: 30px;
      background: linear-gradient( 180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
    }
  }
  .critical-item{
    width: 420px;
    height: 360px;
    background: #FFF9F0;
    border-radius: 10px;
    padding-top: 36px;
    padding-left: 20px;
    img{
      display: block;
      width: 36px;
      // height: 34px;
      margin-bottom: 12px;
      margin-left: 20px;
    }
    .cri-box{
      height: 172px;
    }
    .cri-text{
      a{
        display: block;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 16px;
        color: rgba(0,0,0,0.8);
        width: 380px;
        line-height: 43px;
        padding-left: 20px;
        transition: all 0.5s;
        background: url('@a/images/resourceCenter/go.png') right 10px top 10px no-repeat;
        background-size: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 35px;
        border-radius: 4px;
      }
      a:hover{
        background: url('@a/images/resourceCenter/go.png') right 10px top 10px no-repeat;
        background-size: 20px 20px;
        background-color: rgba(98,98,98,0.04);
      }
    }
  }
  .color0{
    background: #F5FFF8;
  }
  .color1{
    background: #F4F9FE;
  }
  .color2{
    background: #FFF9F0;
  }
  .cri-page{
    margin-top: 28px;
    padding-left: 20px;
    span{
      display: inline-block;
      width: 18px;
      height: 18px;
      text-align: center;
      line-height: 18px;
      margin-right: 14px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0,0,0,0.5);
      cursor: pointer;
      transition: all 0.5s;
      border-radius: 3px;
    }
    span.activeYellow{
      background:#FB9716 ;
      color: #fff;
    }
  }
  .page-down{
    display: none;
  }
  .traningTit{
    margin-top: 160px;
  }
  
}

@media screen and (max-width: 750px) {
  .resouceSearch .fixSarch .searc-box .search-tit{
    font-size: 16px;
  }
  .resouceSearch{
    padding-top: 126px;
    background-image:  url('@a/images/resourceCenter/resourceCenter.png');
    background-size: 100% 400px;
  }
  .resouceSearch .fixSarch{
    padding-top: 0px;
  }

  .resouceSearch::before{
    display: none;
  }
  .resouceSearch::after{
    display: none;
  }
  .resouceSearch .search .searc-box {
    width: auto;
    margin: 0 auto;
    padding: 0 20px;
  }
  .resouceSearch .search span.search-inline{
    display: inline-block;
    width: 300px;
  }
  .resouceSearch .search.fixSarch span.search-inline{
    display: inline-block;
    width: 200px;
  }
  
  .resouceSearch .search-text{
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
    line-height: 22px;
    text-align: center;
    margin-bottom: 60px;
    margin: 0 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .critical-main{
    display: block;
    justify-content: space-between;
    width: auto;
    margin: 0 20px;
  }
  .critical-main .critical-left {
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    margin-bottom: 10px;
  }
  .critical-main .critical-left img{
    display: block;
    width: 100%;
    height: auto;
    transition: all 0.5s;
  }
  .critical-main .critical-left .left-text {
    position: absolute;
    width: 100%;
    left: 0px;
    bottom: 0px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 60px;
    line-height: 60px;
    padding-left: 30px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  }

  .critical-main .critical-item {
    width: 100%;
    height: auto;
    background: #FFF9F0;
    border-radius: 10px;
    padding-top: 36px;
    padding-left: 10px;
    padding-bottom: 20px;
    margin-top: 20px;
  }
  .critical-main .critical-item .cri-text a{
    display: block;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    width: 100%;
  }
  .resouceSearch .bigTitle{
    margin-top: 30px;
  }

  .traningTit{
    margin-top: 40px;
  }
  .cri-page{
    display: none;
  }
  .critical-main .critical-item .cri-box{
    height: auto;
    margin-bottom: 20px;
  }
  .critical-main .page-down{
    display: block;
    height: 33px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 14px;
    color: rgba(0,0,0,0.6);
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 24px;
    transition: all 0.5s;
    .v-icon.v-icon {
      vertical-align: -5px;
    }

  }

}
</style>
<style lang="scss">

.search .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: #fff;
}
.search .theme--light.v-text-field--filled > .v-input__control > .v-input__slot:hover {
    background: #fff;
}

.fixSarch .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
   background: #F9F9F9;
   border-radius: 27px;
   border: 1px solid #DFDFDF; 
}

.search  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-top: 0px;
    margin-bottom: 8px;
    display: none;
}
.search .v-text-field input {
  padding-left: 10px;
}

.search .mdi-magnify::before {
    content: "\F0349";
    font-size: 0px;
    width: 24px;
    height: 24px;
    background: url('@a/images/resourceCenter/search.png') center top 1px no-repeat;
   background-size: 20px 20px;
}
.fixSarch{
  .v-messages{
    display: none;
  }
  .v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
      align-items: stretch;
      min-height: 44px;
  }
  .v-text-field--full-width .v-input__prepend-outer, .v-text-field--full-width .v-input__prepend-inner, .v-text-field--full-width .v-input__append-inner, .v-text-field--full-width .v-input__append-outer, .v-text-field--enclosed .v-input__prepend-outer, .v-text-field--enclosed .v-input__prepend-inner, .v-text-field--enclosed .v-input__append-inner, .v-text-field--enclosed .v-input__append-outer {
      margin-top: 10px;
  }
  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: #F9F9F9;
    border-radius: 27px;
    border: 1px solid #DFDFDF;
    width: 300px;
  }
  span.search-inline{
    display: inline-block;
    width: 300px;
    padding-top: 4px;
  }

}
.resouceSearch .fixSarch span.search-inline {
    display: inline-block;
    width: 300px;
}

@media screen and (max-width: 750px) {

  .fixSarch .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    width: 200px;
  }
  .resouceSearch-index{
    .v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
      align-items: stretch;
      min-height: 46px;
    }
    .v-text-field--full-width .v-input__prepend-outer, .v-text-field--full-width .v-input__prepend-inner, .v-text-field--full-width .v-input__append-inner, .v-text-field--full-width .v-input__append-outer, .v-text-field--enclosed .v-input__prepend-outer, .v-text-field--enclosed .v-input__prepend-inner, .v-text-field--enclosed .v-input__append-inner, .v-text-field--enclosed .v-input__append-outer {
      margin-top: 12px;
    }
  }
  
  .resouceSearch-index .fixSarch{
    .v-input__control{
      padding-top: 7px;
    }
    .v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
      align-items: stretch;
      min-height: 38px;
    }
    .v-text-field--full-width .v-input__prepend-outer, .v-text-field--full-width .v-input__prepend-inner, .v-text-field--full-width .v-input__append-inner, .v-text-field--full-width .v-input__append-outer, .v-text-field--enclosed .v-input__prepend-outer, .v-text-field--enclosed .v-input__prepend-inner, .v-text-field--enclosed .v-input__append-inner, .v-text-field--enclosed .v-input__append-outer {
      margin-top: 7px;
    }
  }

  
  
}



</style>
<template>
  <div class="technology-slides">
      <div class="technology-slide">
        <div class="slide-main" v-for="(item,index) in slides" :key="index">
          <div class="slide-box" v-show="item.value ==actived">
            <div class="slide-left">
              <div class="slide-tit">
                <span></span><br>
                让内容出海更简单
              </div>
              <div class="slide-titbt"></div>
              <div class="slide-smtit">
                <img src="@a/images/home/persons.png" alt="小五出海" />
                <span class="yellow"> {{ item.numb }}</span>
                {{ item.smTit }}
              </div>
              <div >
                <span class="free" @click="toRegister">
                  免费体验
                  <img src="@a/images/foot/go.png" alt=""/>
                </span>
              </div>
            </div>
            <div class="slide-right">
              <img src="@a/images/home/facebookpng.png" alt="小五出海"  class="icons-image slide2" />
              <img src="@a/images/home/ins.png" alt="小五出海"  class="icons-image slide3" />
              <img src="@a/images/home/tiktok.png" alt="小五出海"  class="icons-image slide6" />
              <img src="@a/images/home/X@2x.png" alt="小五出海"  class="icons-image slide7" />
              <img src="@a/images/home/d@2x.png" alt="小五出海"  class="icons-image slide8" />
              <img src="@a/images/home/treads@2x.png" alt="小五出海"  class="icons-image slide9" />
              <img src="@a/images/home/YouTube.png" alt="小五出海"  class="icons-image slide5" />
            </div>
          </div>
        </div>
        <div class="slide-btn">
        </div>
    </div>
   
  </div>
</template>

<script>
import { isMobile } from '@/utils/index'
export default {
  name: 'technology-slides',
  props: {},
  components: {
  },
  data() {
    return {
        actived:1,
        slides: [
          {
            title:'小五出海通让内容出海更简单',
            smTit:'创作者都在用的出海服务平台',
            numb:'4000+',
            value:1,
          },
        ],
    }
  },
  methods: {
    handerSlider(value){
      this.actived = value
    },
    toRegister(){
      if(isMobile()){
        window.location.href = '/erwei'
      } else {
       window.location.href = 'https://cr.xiaowutube.com/register'
      }
    },
  },
  mounted() {},
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.technology-slides{
  width: 1200px;
  margin: 0 auto;
}
.technology{
  width: 100%;
  height: 700px;
  background: linear-gradient( 7deg, rgba(255,158,81,0) 0%, rgba(255,125,81,0.38) 100%), linear-gradient( 172deg, rgba(255,242,75,0.12) 0%, rgba(255,253,75,0) 100%), #FFFFFF;
  opacity: 0.54;
  .green{
    background: transparent;
  }
  .secondary{
    background: transparent;
  }
  .slide-box{
    display: flex;
    padding-top: 0px;
    padding-bottom: 30px;
    .slide-left{
      width: 600px;
      padding-right: 50px;
      padding-left: 50px;
      padding-top: 110px;
      .slide-tit{
        font-family: AlibabaPuHuiTiM;
        font-size: 50px;
        color: #fff;
        line-height: 64px;
        text-align: left;
        font-style: normal;
        span{
          display: inline-block;
          width: 230px;
          height: 65px;
          background: url('@a/images/home/hometop.png') center no-repeat;
          background-size: 100%;
        }
      }
      .slide-titbt{
        width: 154px;
        height: 18px;
        background: url('@a/images/home/toptitlebt.png') center no-repeat;
        background-size: 100%;
        position: relative;
        left: 253px;
        top: -11px;
      }
      
       .slide-smtit{
        font-family: AlibabaPuHuiTiM;
        font-size: 20px;
        color: #fff;
        line-height: 27px;
        text-align: left;
        font-style: normal;
        width: 440px;
        margin-bottom: 40px;
        margin-top: 30px;
        span{
          color: #FB9716;
          font-size: 20px;
        }
        img{
          display: inline-block;
          width: 42px;
          height: 18px;
          vertical-align: -2px;
        }
       }
       .free{
        display: inline-block;
        text-align: center;
        font-family: AlibabaPuHuiTiM;
        font-size: 18px;
        color: rgba(0,0,0,0.8);
        cursor: pointer;
        width: 190px;
        height: 48px;
        line-height: 48px;
        background: linear-gradient( 48deg, #FFDD2F 0%, #FFA50C 15%, #FF673F 85%, #FF4F91 100%);
        border-radius: 6px;
        color: #fff;
        transition: all 0.35s;
        position: relative;
        span{
          font-family: AlibabaPuHuiTiM;
          font-size: 20px;
          display: inline-block;
          padding-left: 10px;
          text-align: right;
        }
        img{
          display: inline-block;
          width: 26px;
          height: 26px;
          vertical-align: -8px;
          transition: all 0.35s;
          position: relative;
          left: 0px;
        }
       }
       .free:hover span{
          padding-left: 0px;
          text-align: left;
        }
       .free:hover img{
          display: inline-block;
          left: 8px;
        }
       .free:hover{
        background: linear-gradient( 48deg, #FFDD2F 0%, #FFA50C 5%, #FF673F 55%, #FF4F91 100%);
       }
    }
    .slide-right{
      flex: 0 0 600px;
      height: 548px;
      position: relative;
      background: url('@a/images/home/sliderbk.png') center no-repeat;
      background-size: 100% 100%;
      img{
        position: absolute;
      }
      .icons-image{
        -webkit-animation: pulse ease-in-out 5s infinite;
        animation: pulse ease-in-out 5s infinite;
        -webkit-transform: scale(0.7);
        transform: scale(0.7);
      }
      .slide1 {
        left: 86px;
        top: 110px;
        width: 122px;
        height: 122px;
        -webkit-animation-delay: 3s;
        animation-delay: 3s;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
      }
      .slide2 {
        left: 182px;
        top: 112px;
        width: 122px;
        height: 122px;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
      }
      .slide3 {
        left: 331px;
        top: 110px;
        width: 122px;
        height: 122px;
        -webkit-animation-delay: 1.5s;
        animation-delay: 1.5s;
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%;
      }
      .slide4 {
        left: 121px;
        top: 220px;
        width: 86px;
        height: 86px;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
      }
      .slide5 {
        left: 119px;
        top: 153px;
        width: 345px;
        height: 291px;
        -webkit-animation-delay: 4s;
        animation-delay: 4s;
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;

      }
      .slide6 {
        left: 397px;
        top: 262px;
        width: 98px;
        height: 98px;
        -webkit-animation-delay: 3s;
        animation-delay: 3s;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
      }
      .slide7 {
        left: 87px;
        top: 254px;
        width: 86px;
        height: 86px;
        -webkit-animation-delay: 3.5s;
        animation-delay: 3.5s;
        -webkit-transform-origin: 100% 0;
        transform-origin: 100% 0;
      }
      .slide8 {
        left: 203px;
        top: 374px;
        width: 98px;
        height: 98px;
        // -webkit-animation-delay: 4s;
        // animation-delay: 4s;
        // -webkit-transform-origin: 50% 50%;
        // transform-origin: 50% 50%;
        -webkit-animation-delay: 1.5s;
        animation-delay: 1.5s;
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%;
      }
      .slide9{
        left: 340px;
        top: 357px;
        width: 82px;
        height: 82px;
        // -webkit-animation-delay: 0.5s;
        // animation-delay: 0.5s;
        // -webkit-transform-origin: 50% 50%;
        // transform-origin: 50% 50%;
        -webkit-animation-delay: 2s;
        animation-delay: 2s;
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
      }

    }
    .v-system-bar--lights-out{
      display: none;
    }
  }
}
.slide-btn{
  text-align: center;
  span{
    display: inline-block;
    width: 80px;
    height: 6px;
    background: #EDF0F3;
    border-radius: 3px;
    cursor: pointer;
  }
  span.slideActived{
    background: #FFD39B;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }

  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }

  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  100% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
}


@media screen and (max-width: 750px) {
  .homePage .technology{
    padding-bottom: 0px;
  }
  .technology {
    overflow: hidden;
    .slide-box{
      max-width: 390px;
      margin: 0 auto;
    }
  }
  .technology .slide-box .slide-left .slide-tit span {
    display: inline-block;
    width: 210px;
    height: 56px;
    background: url('@a/images/home/hometop.png') center no-repeat;
    background-size: 100%;
  }

  .technology-slides{
    width: auto;
  }
  .technology{
    .slide-box{
      display: block;
      padding-top: 0px;
      padding-bottom: 30px;
      flex-wrap: wrap;
      .slide-left {
        width: auto;
         padding-right: 0px; 
         padding-left: 20px; 
         padding-top: 60px;
        .slide-tit {
          font-family: AlibabaPuHuiTiM;
          font-size: 38px;
          color: #fff;
          line-height: 44px;
          text-align: left;
          font-style: normal;
        }
        .slide-titbt{
          width: 184px;
          height: 22px;
          background: url('@a/images/home/toptitlebt.png') center no-repeat;
          background-size: 100%;
          position: relative;
          left: 113px;
          top: -8px;
        }
        .slide-smtit {
          font-family: AlibabaPuHuiTiM;
          font-size: 14px;
          color: #fff;
          line-height: 27px;
          text-align: left;
          font-style: normal;
          width: auto;
          margin-bottom: 36px;
          margin-top: 15px;
          span{
            font-size: 14px;
          }
        }
      }
      .slide-right {
        // flex: 0 0 600px;
        width: auto;
        height: 480px;
        .slide2 {
          left: 75px;
          top: 125px;
          width: 110px;
          height:110px;
        }
        .slide3 {
          left: 231px;
          top: 105px;
          width: 122px;
          height: 122px;
        }
        .slide4 {
          left: 31px;
          top: 190px;
          width: 86px;
          height: 86px;
        }
        .slide5 {
          left: 27px;
          top: 123px;
          width: 345px;
          height: 291px;
        }
        .slide6 {
          left: 278px;
          top: 222px;
          width: 98px;
          height: 98px;
        }
        .slide7 {
          left: 17px;
          top: 233px;
          width: 96px;
          height: 96px;
        }
        .slide8 {
          left: 113px;
          top: 284px;
          width: 98px;
          height: 98px;
          
        }
        .slide9{
          left: 238px;
          top: 284px;
          width: 92px;
          height: 92px;
        }

        .icons-image{
          animation: none;
          transform: scale(0.7);
        }

       
      }
    }
    
  }
}
</style>
<style lang="scss">
</style>


import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import Foot from '@/views/foot/index'
import Home from '@/views/home/index'
import Solution from '@/views/Solution/index'
import ResourceCenter from '@/views/ResourceCenter/index'
import ResourceSearch from '@/views/ResourceCenter/ResourceSearch.vue'
import ResourceDetail from '@/views/ResourceCenter/ResourceDetail.vue'
import AboutUs from '@/views/AboutUs/index'
import Erwei from '@/views/ResourceCenter/erwei.vue'


const routes = [
  { path: '/', component: Home },
  { path: '/home', component: Home },
  { path: '/solution', component: Solution },
  { path: '/resourceCenter', component: ResourceCenter },
  { path: '/resourceSearch', component: ResourceSearch },
  { path: '/resourceDetail', component: ResourceDetail },
  { path: '/aboutUs', component: AboutUs },
  { path: '/foot', component: Foot },
  { path: '/erWei', component: Erwei },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router

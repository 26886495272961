<template>  
  <div class="erweiDetail">
    <Header :activedUrl="activedUrl" :background="background"></Header>

    <div class="tabbox-goway" @click="goBack">
      <img src="@a/images/resourceCenter/goway.png" alt="小五出海返回上一页" class="goway" />
      返回上一页
    </div>

    <div class="detail-main">
      
      <img src="@a/images/foot/erwei.jpg" alt="小五出海" class="erwei" />
      <div class="des">微信扫码打开【小五出海通】小程序<br>开启内容出海之旅</div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import Header from '@v/header/index'
import Foot from '@v/foot/index'

export default {
  name: 'ResourceDetail',
  props: {},
  components: {
    Header, Foot,
  },
  data() {
    return {
      activedUrl:'login',
      background:'resourceCenter',
    }
  },
  methods: {
   
    goBack(){
      this.$router.go(-1)
    },
  },
  mounted() {
    
  },
 
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">

@media screen and (max-width: 750px) {
  .erweiDetail{
    padding-top: 120px;
    .tabbox-goway{
      display: block;
      position: fixed;
      left: 0px;
      top: 80px;
      background-color: #fff;
      padding-left: 20px;
      line-height: 40px;
      width: 100%;
      z-index: 2;
      .goway{
        display: inline-block;
        width: 24px;
        vertical-align: -6px;
      }
    }
    .detail-main{
      padding: 60px 0 10px 0;
      margin: 0 auto;
      text-align: center;
      .erwei{
        display: inline-block;
        width: 180px;
      }
      .des{
        font-family: AlibabaSans_Light !important;
        color:rgba(0,0,0,1);
        line-height: 26px;
        font-size: 16px;
        text-align: center;
        padding-bottom: 80px;
        margin-top: 20px;
      }
      
    }
    
    

  } 
    
}

</style>

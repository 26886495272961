<template>
  <div id="aboutUs" ref="aboutUsRef" class="aboutUs-main">
    
    <div class="aboutUsBk">
      <Header :activedUrl="activedUrl" :background="background"></Header>
      <div class="w1200 about-our">
        <div class="our-left">
          <img src="@a/images/aboutUs/abouts.png" alt="小五出海" >
        </div>
        <img src="@a/images/aboutUs/company.png" alt="小五出海"  class="aboutUsNet">
        <div class="our-right">
          <div class="our-tit">关于我们</div>
          <div class="our-desbox">
            <div class="our-des">
              <span>小五兄弟</span>是一家致力于内容海外发行及为创作者增值创收的科技公司。</div>
            <div class="our-des">以成为“领先的创作者出海服务平台”为愿景，通过自研算法、AIGC、智能化分析等前沿科技，不断提升互联网内容在创作、翻译、分发、推广及数据分析方面的运营效率。助力创作者、影视机构、IP、版权方出海，并携手探索内容的海外价值，开辟新的商业化路径。</div>
          </div>
        </div>
      </div>
    </div>

    <div class="development-bk">
      <div class="bigTitle" title="发展历程">发展历程</div>
      <div class="development-year">
        <div class="item-year" v-for="(item,i) in yearList" :key="i" :class="{'yellowYear':item ==activedYear }" @click="clickYear(item)">{{ item }}</div>
      </div>
      <div class="development-main">
        <div class="development-box" style="transition-duration: 300ms " :style="{ transform: `translate3d(${goway}px, 0px, 0px)` }">
          <div class="develop-item" v-for="(item,i) in developList" :key="i">
            <div class="item-tit">{{ item.time }}</div>
            <div class="item-des">{{ item.des }}</div>
          </div>
        </div>
      </div>
      <div class="development-mains">
        <div class="prew" @click="clickShift(false)"></div>
        <div class="next" @click="clickShift(true)"></div>
      </div>
    </div>

    <div class="honor-bk">
      <div class="bigTitle" title="荣誉">荣誉</div>
      <div class="honor-box">
        <div class="honor-item">
          <div class="ho-des"><span>2024年</span><br>
             《YouTube钻石创作者》</div>
        </div>
        <div class="honor-item">
          <div class="ho-des"><span>2023年</span><br>
            《YouTube年度多格式创新运营奖》</div>
        </div>
        <div class="honor-item">
          <div class="ho-des"><span>2022年</span><br>
            《YouTube年度最佳短影音运营奖》</div>
        </div>
        <div class="honor-item">
          <div class="ho-des"><span>2022年</span><br>
            《Facebook优质合作伙伴》</div>
        </div>
        <div class="honor-item">
          <div class="ho-des"><span>2020年</span><br>
            《YouTube年度MCN伙伴》</div>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Header from '@v/header/index'
import Foot from '@v/foot/index'
import { isMobile } from '@/utils/index'
export default {
  name: 'aboutPage',
  props: {},
  components: {
    Header, Foot,
  },
  data() {
    return {
      activedUrl:'aboutUs',
      background:'aboutUs',
      width: window.innerWidth,
      yearList:[2017,2018,2019,2020,2021,2022,2023,2024],
      developList:[
        {
          time:'2017年',
          des:'小五兄弟于杭州、晋城两地创办成立',
        },
        {
          time:'2018年',
          des:'正式成为YouTube官方认证MCN机构',
        },
        {
          time:'2019年',
          des:'在香港注册分公司，成立香港办事部',
        },
        {
          time:'2020年',
          des:'成功入选YouTube全球优质供应商名录',
        },
        {
          time:'2020年',
          des:'荣获YouTube年度MCN伙伴称号',
        },
        {
          time:'2022年',
          des:'上榜Facebook优质案例库，获得业内首次官方推广',
        },
        {
          time:'2022年',
          des:'荣获YouTube年度最佳短影音运营奖',
        },
        {
          time:'2023年',
          des:'小五兄弟香港分公司升格为小五兄弟海外市场总部',
        },
        {
          time:'2023年',
          des:'荣获YouTube年度多格式创新运营奖',
        },
        {
          time:'2024年',
          des:'荣获五块YouTube钻石创作者奖牌',
        },
      ],
      goway:-1620,
      goIndex:6,
      activedYear:2024,
      isH5:false,
    }
  },
  methods: {
    
    handerShift(value){
      if(value && this.goIndex < 6 ){
        this.goway = this.goway - 270
        this.goIndex= this.goIndex +1  
      }
      if(!value && this.goIndex > 0 ){
        this.goway = this.goway + 270
        this.goIndex= this.goIndex -1  
      }
      this.activedYear = this.developList[this.goIndex+3].time.slice(0,4)
    },
    handerYear(value){
      this.activedYear = value
      let index =0
      this.developList.forEach((item,k) =>{
          if(item.time == value+'年'){
              index = k
          }
      })
      if( value==2021){
        index = 4 
      }
      if(index < 4){
        this.goIndex = 0
      }else{
        this.goIndex = index - 3
      }
      this.goway = - 270*this.goIndex
    },
    
    handerShiftH5(value){
      if(value && this.goIndex < 9 ){
        this.goway = this.goway - 270
        this.goIndex= this.goIndex +1  
      }
      if(!value && this.goIndex > 0 ){
        this.goway = this.goway + 270
        this.goIndex= this.goIndex -1  
      }
      this.activedYear = this.developList[this.goIndex].time.slice(0,4)
    },
    handerYearH5(value){
      let index = this.developList.findIndex(obj => obj.time === value+'年');
      if(index ==-1){
        index = 4 //2021 无，暂且取2020
      }
      this.goIndex = index
      this.goway = - 270*this.goIndex
      this.activedYear = value
    },
    clickShift(value){
      if(this.isH5){
        this.handerShiftH5(value)
      }else{
        this.handerShift(value)
      }
    },
    clickYear(value){
      if(this.isH5){
        this.handerYearH5(value)
      }else{
        this.handerYear(value)
      }
    }
    

  },
  mounted() {
    console.log('phone',isMobile())
    this.isH5 = isMobile()
    if(this.isH5){
      this.handerYearH5(2024)
    }
  },
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.aboutUs-main{
  overflow-x: scroll;
}
.aboutUsBk {
  padding-top: 180px;
  padding-bottom: 110px;
  background-image:  url('@a/images/aboutUs/aboutUsbk.png');
  background-size: 100% 100%;
  .about-our{
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    position: relative;
    .aboutUsNet{
      position: absolute;
      left: 42%;
      bottom: -6px;
      width: 151px;
      height: 151px;
    }
    .our-left{
      width: 600px;
      padding-left: 20px;
      margin-right: 20px;
      img{
        display: inline-block;
        width: 570px;
        height: 392px;
        border-radius: 16px;
      }
    }
    .our-right{
      flex: 1;
      padding-top: 20px;
      .our-tit{
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 40px;
        color: rgba(0,0,0,0.8);
        line-height: 55px;
        margin-bottom: 56px;
        position: relative;
        padding-left: 30px;
      }
      .our-tit::after {
        content: "";
        position: absolute;
        left: 30px;
        bottom: -20px;
        background: url('@a/images/aboutUs/titImg.png') center no-repeat;
        width: 183px;
        height: 29px;
        background-size: 100%;
      }

      .our-desbox{
        width: 570px;
        height: 296px;
        background: url('@a/images/aboutUs/gongsi.png') center no-repeat;
        padding: 40px 30px;
        background-size: 100%;
      }
      .our-des{
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-size: 20px;
        color: rgba(0,0,0,0.8);
        line-height: 28px;
        margin-bottom: 16px;
        span{
          font-size: 20px;
          color: #FB9716;
          font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        }
      }
    }
  }

}

.development-bk{
  max-width: 1520px;
  border-radius: 20px;
  margin: 0 auto;
  padding-top: 1px;
  padding-bottom: 120px;
  background: url('@a/images/aboutUs/faz.png') center no-repeat;
  background-size: 100% 100%;
  background-color: #F9FAFA;
  .bigTitle{
    margin-top: 50px;
  }
  .development-year{
    max-width: 1160px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .item-year{
      font-family: AlibabaPuHuiTiM;
      font-size: 20px;
      color: rgba(0,0,0,0.8);
      line-height: 27px;
      text-align: center;
      flex: 1;
      padding-bottom: 24px;
      border-bottom: 2px solid rgba(150,150,150,0.16);
      position: relative;
      cursor: pointer;
    }
    .item-year::after{
      content: "";
      position: absolute;
      bottom: -7px;
      left: 50%;
      margin-left: -6px;
      width: 12px;
      height: 12px;
      background: #DEDEDE;
      border-radius: 6px;
    }
    .yellowYear{
      color:#FB9716;
    }
    .yellowYear::after{
      content: "";
      position: absolute;
      bottom: -7px;
      left: 50%;
      margin-left: -6px;
      width: 12px;
      height: 12px;
      background:#fff;
      border-radius: 6px;
      border: 2px solid #FB9716;
    }
  }
  .development-main{
    max-width: 1080px;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 60px;
    position: relative;
  }
  .development-mains{
    width: 1200px;
    margin: 0 auto;
    position: relative;
  }
  .prew{
    position: absolute;
    left: 20px;
    top: -72px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: url('@a/images/aboutUs/toLeft.png') center no-repeat;
    background-size: 100% 100%;
  }
  .next{
    position: absolute;
    right: 35px;
    top: -72px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: url('@a/images/aboutUs/toRight.png') center no-repeat;
    background-size: 100% 100%;
  }
  .development-box{
    display: flex;
    width: 2700px;
  }
  .develop-item{
    flex: 0 0 250px;
    height: 135px;
    background: rgba(255,253,250,0.7);
    border-radius: 12px;
    border: 1px solid rgba(251,151,22,0.3);
    backdrop-filter: blur(10px);
    margin-right: 20px;
    padding: 24px 24px 0 24px;
    .item-tit{
      font-family: AlibabaPuHuiTiM;
      font-size: 20px;
      color: #FB9716;
      line-height: 27px;
    }
    .item-des{
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 16px;
      color: rgba(0,0,0,0.8);
      line-height: 24px;
      margin-top: 12px;
    }
  }
}
.honor-bk{
  margin-bottom: 100px;
  .bigTitle{
    margin-top: 120px;
  }
}
.honor-box{
  max-width: 1440px;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .honor-item{
    max-width: 426px;
    min-width: 380px;
    height: 160px;
    background: #FFFFFF;
    box-shadow: 0px 10px 30px 0px rgba(50,35,12,0.06);
    border-radius: 16px;
    border: 1px solid #ECECEC;
    margin-right: 20px;
    margin-bottom: 20px;
    padding-top: 46px;
    background: url('@a/images/aboutUs/rong.png') center no-repeat;
    background-size: 100% 100%;
    .ho-des{
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 20px;
      color: #5C3400;
      line-height: 33px;
      text-align: center;
      span{
        font-size: 24px;
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      }
    }

  }
  .honor-item:nth-child(3n+3){
    margin-right: 0px;
  }
}

@media screen and (max-width: 750px) {
  .aboutUsBk {
    padding-top: 106px;
    padding-bottom: 60px;
    .about-our {
      display: block;
      margin: 0 auto;
      .our-left {
        width: auto;
        padding-left: 20px;
        margin-right: 20px;
        img{
          width: 100%;
          height: auto;
        }
      }
      .aboutUsNet{
        display: none;
      }
      .our-right {
        flex: 1;
        padding-top: 20px;
        margin: 0 20px;
        overflow: hidden;
        .our-tit {
          font-size: 32px;
          line-height: 55px;
          width: 390px;
          margin: 0 auto;
          margin-bottom: 26px;
          padding-left: 0;
          text-align: center;
        }
        .our-tit::after {
          content: "";
          position: absolute;
          left: 122px;
          bottom: -8px;
          width: 144px;
          height: 18px;
          background-size: 100% 100%;
        }
        .our-desbox {
          width: 100%;
          height: auto;
          padding: 20px 20px;
          background-size: 100% 100%;
        }
        .our-des{
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;
          span{
            font-size: 18px;
          }
        }
      }

    }
  }

  .development-bk{
    padding-top: 1px;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;
    background: url('@a/images/aboutUs/faz.png') center no-repeat;
    background-size: 100% ;
  }
  .development-bk .bigTitle{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .development-bk .development-year .item-year {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }
  .development-bk .development-year .yellowYear{
    color: #FB9716;
  }
  .development-bk .next{
    right: 26px;
  }
  .development-bk .development-main {
    width: 270px;
    margin: 0 auto;
    margin-top: 60px;
    left: 6px;
  }
  .development-bk .development-mains {
    width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .honor-bk {
    margin-bottom: 30px;
    overflow: hidden;
  }
  .honor-bk .bigTitle{
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .honor-box {
    max-width: 390px;
    min-width: auto;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .honor-box .honor-item {
    width: 100%;
    min-width: auto;
    height: 96px;
    padding-top: 14px;
    .ho-des {
      font-size: 18px;
      line-height: 33px;
      span{
        font-size: 18px;
      }
    }
  }
  .honor-box .honor-item:nth-child(3n+3){
    margin-right: 0px;
  }
}

</style>

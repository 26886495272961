export const problemList=[
  {
    title:'跟你们合作收费吗？',
    text:'全程免费合作，不获利轻松解约。',
  },
  {
    title:'签约后中途想解约怎么办？',
    text:'小五出海通提供人性化客户服务，实时关注你的需求，依照合同条款，全程协商，让解约不繁琐。',
  },
  {
    title:'签约后多久能获利？',
    text:'小五出海通根据各平台获利门槛进行内容定位，满足平台设置的获利门槛后，结合领域和账号发展情况，在 Youtube 获利约3-6个月，Facebook 约5-8个月，部分优质内容，仅需约一周即可获利。超过8个月无法开启获利，可以随时提出解约。',
  },
  {
    title:'自己做海外和找你们合作有什么区别？',
    text:'与小五出海通合作，可以最快速的开通获利，让你打击盗版更加高效便捷，出现账号级别相关问题，也可直接对接平台方解决，让出海更简单。',
  },
  {
    title:'我的内容有很多盗版，找你们能提供什么解决方案吗？',
    text:'小五出海通配备专业的版权保护团队，为你的内容提供从注册、监控到维权的全方位服务。让侵权者无处遁形，让你的创意安全无虞。',
  },
  {
    title:'个人账号在海外平台被封怎么办？',
    text:'我们提供专业团队服务，直通海外平台经理，及时解决账号问题，让出海高效便捷不头疼！',
  }
]

export const toSeeServe=[
  {
    icon:require('@a/images/home/part1.png'),
    iconYel:require('@a/images/home/party1.png'),
    title:'一键多平台分发',
    detailDes:'秒发全球热门平台，YouTube、Facebook、TikTok一网打尽（更多平台陆续接入中）',
    detailImg:require('@a/images/home/tosee.png'),
    id:1,
    videoUrl:'https://xiaowu-web.oss-cn-beijing.aliyuncs.com/video/%E5%8D%96%E7%82%B9%E8%A7%86%E9%A2%91/%E4%B8%80%E9%94%AE%E5%88%86%E5%8F%91.mp4'
   },
  {
    icon:require('@a/images/home/part2.png'),
    iconYel:require('@a/images/home/party2.png'),
    title:'智能版权保护监控',
    detailDes:'专业团队护航，让你的创意安全无虞。',
    detailImg:require('@a/images/home/tosee.png'),
    id:2,
    videoUrl:'https://xiaowu-web.oss-cn-beijing.aliyuncs.com/video/%E5%8D%96%E7%82%B9%E8%A7%86%E9%A2%91/%E6%99%BA%E8%83%BD%E7%89%88%E6%9D%83.mp4'
  },
  {
    icon:require('@a/images/home/part3.png'),
    iconYel:require('@a/images/home/party3.png'),
    title:'可视化收益工具管理',
    detailDes:'一站式全方位运营，涵盖内容策划到变现的所有环节，提供清晰透明的结算报表，支持多种收益模式，让你从多渠道获取稳定收益。',
    detailImg:require('@a/images/home/tosee.png'),
    id:3,
    videoUrl:'https://xiaowu-web.oss-cn-beijing.aliyuncs.com/video/%E5%8D%96%E7%82%B9%E8%A7%86%E9%A2%91/%E5%8F%AF%E8%A7%86%E5%8C%96%E6%94%B6%E7%9B%8A.mp4'
  },
  {
    icon:require('@a/images/home/part4.png'),
    iconYel:require('@a/images/home/party4.png'),
    title:'运营专家1对1指导',
    detailDes:'提供全面化数据挖掘分析，帮你深入了解内容表现，量身定制个性化推广策略，最大化提升曝光率和收益潜力',
    detailImg:require('@a/images/home/tosee.png'),
    id:4,
    videoUrl:'https://xiaowu-web.oss-cn-beijing.aliyuncs.com/video/%E5%8D%96%E7%82%B9%E8%A7%86%E9%A2%91/%E8%BF%90%E8%90%A5%E4%B8%93%E5%AE%B6.mp4'
  },
  {
    icon:require('@a/images/home/part5.png'),
    iconYel:require('@a/images/home/party5.png'),
    title:'海外平台技术支持',
    detailDes:'对接海外平台经理，及时解决账号问题。',
    detailImg:require('@a/images/home/tosee.png'),
    id:5,
    videoUrl:'https://xiaowu-web.oss-cn-beijing.aliyuncs.com/video/%E5%87%BA%E6%B5%B7%E9%80%9A5(1).mp4'
  },
  
]


export const PartList=[
  {
    img:require('@a/images/home/person1.png'),
    name:'@GuiGe',
    titText:'知名博主 · 粉丝数 1950万',
    des:'小五出海通是我接触下来最好的一个团队和平台，在服务的温度和理念上与我非常契合，客服团队给了我很多日常的陪伴与支持。在小五出海通的运营专家策略1V1指导下，我能深入了解自己创作的内容表现情况，选择最优创作方向。在小五出海通为我量身定制的个性化推广策略下，仅仅11个月便收获1000万以上的粉丝，让我对创作更有动力了！'
  },
  {
    img:require('@a/images/home/person2.png'),
    name:'@萌萌与舞桐',
    titText:'知名博主 · 粉丝数 1840万',
    des:'选择小五出海通作为内容出海的代理商，除了让我的出海简单高效收益更可观之外，更是因为小五出海通能够很好地保障我的内容在海外的版权安全，让我专注创作。和小五合作仅14个月便收获了1000万以上粉丝的关注，上榜全球流量排行第6名。后续我将会更多利用平台提供的功能，持续提升内容的海外影响力，实现创收二合一。'
  },
  {
    img:require('@a/images/home/person3.png'),
    name:'@陕北霞姐',
    titText:'知名博主 · 粉丝数 60.2万',
    des:'小五出海通签约流程简单便捷，弥补了中老年人对海外互联网平台的认知空缺。我非常感谢小五出海通这个团队给我提供的专业帮助和支持，帮我量身定制适合我的推广策略，让我这样的普通人创作的视频在海外得到广泛关注，也让中国传统美食工艺得到了跨国界的宣传和分享，收益变得更可观！'
  },
]

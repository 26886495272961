import { render, staticRenderFns } from "./erwei.vue?vue&type=template&id=f626e2f2&scoped=true"
import script from "./erwei.vue?vue&type=script&lang=js"
export * from "./erwei.vue?vue&type=script&lang=js"
import style0 from "./erwei.vue?vue&type=style&index=0&id=f626e2f2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f626e2f2",
  null
  
)

export default component.exports
<template>  
  <div class="ResourceDetail">
    <Header :activedUrl="activedUrl" :background="background"></Header>

    <div class="tabbox-goway" @click="goBack">
      <img src="@a/images/resourceCenter/goway.png" alt="小五出海返回上一页" class="goway" />
      返回上一页
    </div>
    <div class="w1200 detail-header">
      <div class="detail-left">
        
        <span @click="toCenter" class="toCenter">资源中心/&nbsp;</span>
        {{ tabValue=='numbs'?'内容出海知识库': '培训活动'}}/&nbsp;
        <span class="details">{{ itemTitle}} </span>
      </div>
      <div class="detail-right"  @click="handleEnter">
        <v-text-field
              class="search-texts"
              v-model="searchValue"
              prepend-inner-icon="mdi-magnify"
              placeholder="搜索"
              :rules="[textLength]"
              filled
              rounded
              clearable
            ></v-text-field>
      </div>
    </div>

    <div class="w1200 detail-main">
      <div class="main-left">
        <div class="main-tab">
          <span :class="{'actived': tabValue=='numbs'}" @click="handerTab('numbs')">内容出海知识库</span>
          <span :class="{'actived': tabValue=='trainings'}" @click="handerTab('trainings')">培训活动</span>
        </div>
        <div class="numbs" v-show="tabValue=='numbs'">
          
          <div class="numb-box" >
            <div class="numbs-item"  v-for="(item,i) in numbList" :key="i" @click="toDetailNumb(item)" :class="{'yellowActd': id==item.id }">{{ item.title }}</div>
            <div class="numbs-item" v-show="numbList.length ==0"> {{searchValue}}搜索，内容出海知识库 暂无数据</div>
          </div>
         
        </div>
        <div class="trainings"  v-show="tabValue!=='numbs'">
          <div class="trainings-item" v-for="(item,i) in trainingsList" :key="i" @click="toDetaiTraining(item)" :class="{'yellowActd': id==item.id }">{{ item.title }}</div>
          <div class="numbs-item" v-show="trainingsList.length ==0"> {{searchValue}}搜索，培训活动 暂无数据</div>
        </div>
      </div>
      <div class="w1200 main-right">
        <div class="content-tit">{{ itemTitle }}</div>
        <div class="contents">

          <div v-show="tabValue=='numbs'" v-html="bodyHtml">
          </div>
          <!-- autoplay="" -->
          <video controls=""  name="media" v-if="tabValue!=='numbs' && trainingCurrent.videoUrl" :key="videoKey" :poster="trainingCurrent.thumbnail">
            <source :src="trainingCurrent.videoUrl" :key="videoKey" type="video/mp4">
          </video>
          
        </div>

      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import Header from '@v/header/index'
import Foot from '@v/foot/index'
import { websitePage } from '@/api'
import { toastInfo } from '@/utils/prompt'
export default {
  name: 'ResourceDetail',
  props: {},
  components: {
    Header, Foot,
  },
  data() {
    return {
      activedUrl:'resourceCenter',
      background:'resourceCenter',
      searchValue:'',
      numbList:[],
      trainingsList:[],

      scrollTop:0,
      tabValue:'numbs',
      itemTitle:'',
      id:'',
      bodyHtml:'',
      trainingCurrent:{},
      videoKey:0,
    }
  },
  methods: {
    textLength(value){
      if(value.length >20 ){
        toastInfo('输入搜索内容,不可大于20字符！')
        this.searchValue = value.slice(0,20)
      }
    },
    handerTab(value){
      this.tabValue = value
      if(value =='numbs'){
        this.toDetailNumb(this.numbList[0])
      }else{
        this.toDetaiTraining(this.trainingsList[0])
       
      }
    },
    toDetailNumb(row){
      if(row && row.id){
        this.itemTitle = row.title
        this.bodyHtml = row.textHtml
        this.id = row.id
      } else{
        
        this.itemTitle = ''
        this.bodyHtml = ''
      }
      
    },
    toDetaiTraining(row){
      if(row && row.id){
        this.itemTitle = row.title
        this.trainingCurrent= row
        this.id = row.id
        this.videoKey = this.videoKey+1
      } else{
        this.itemTitle = ''
        this.trainingCurrent= []
        this.id = ''
      }
    },
    
    getList(parms) {
      websitePage(parms)
        .then(({ data }) => {
          
          const {knowledge_base,train_activity } = data
          this.numbList = knowledge_base
          this.trainingsList =train_activity
          
          if( this.tabValue=='numbs'){
           const currentItem =  knowledge_base.filter(item => item.id==this.id )[0] 
           this.toDetailNumb(currentItem)
          }else{
            const currentItem =  train_activity.filter(item => item.id==this.id )[0] 
            this.toDetaiTraining(currentItem)
          }
        })
        .catch(error => error)
    },
    handleEnter(){
      // window.location.href = '/resourceSearch?value='+this.searchValue;
      window.location.href = '/resourceSearch'
    },
    toCenter(){
      window.location.href = '/resourceCenter';
    },
    goBack(){
      this.$router.go(-1)
    },
  },
  mounted() {
    this.tabValue = this.$route.query.tabValue
    this.id= this.$route.query.id
    this.itemTitle= this.$route.query.itemTitle
    this.getList({})
  },
  beforeDestroy() {
  },
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.ResourceDetail{
  overflow-x: scroll;
  padding-top: 80px;
  min-height: 800px;
  .detail-header{
    display: flex;
    justify-content: space-between;
    background: rgba(255,255,255,0.6);
    box-shadow: inset 0px 1px 0px 0px rgba(210,210,210,0.16), inset 0px -1px 0px 0px rgba(193,193,193,0.2);
    height: 80px;
    .detail-left{
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 16px;
      color: rgba(0,0,0,0.4);
      line-height: 22px;
      line-height: 80px;
      span.details {
        color: rgba(0,0,0,0.8);
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      }
      span.toCenter{
        color: rgba(0,0,0,0.4);
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        cursor: pointer;
      }
    }
    .detail-right{
      padding-top: 18px;
    }   
  }
  .tabbox-goway{
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0,0,0,0.8);
    line-height: 22px;
    margin: 0 auto;
    cursor: pointer;
    border-bottom: 1px solid #EFEFEF;
    display: none;
    img{
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: -4px;
    }
  }
  .detail-main{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 90px;
    .main-left{
      width: 350px;
      margin-right: 30px;
      border-right: 1px solid #EFEFEF;
      padding-left: 24px;
      padding-right: 24px;
      .main-tab{
        width: 300px;
        height: 50px;
        background: #F9FAFA;
        border-radius: 6px;
        padding-top: 4px;
        padding-left: 4px;
        margin-bottom: 16px;
        span{
          display: inline-block;
          width: 146px;
          height: 42px;
          line-height: 42px;
          font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
          font-weight: 400;
          font-size: 18px;
          color: rgba(0,0,0,0.4);
          text-align: center;
          cursor: pointer;
          border-radius: 3px;
        }
        .actived{
          background-color: #fff;
          color: rgba(0,0,0,0.8);
        }
      }
      .numbs-tit{
        font-family: AlibabaPuHuiTiM;
        font-size: 16px;
        color: rgba(0,0,0,0.4);
        line-height: 22px;
        margin-top: 24px;
      }
      .numbs-item{
        padding-left: 12px;
        padding-right: 6px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-size: 16px;
        color: rgba(0,0,0,0.8);
        line-height: 24px;
        margin-top: 6px;
        cursor: pointer;
        border-radius: 5px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
      .numbs-item:hover{
        background: #F2F2F2;
        border-radius: 6px;
      }
      .trainings-item{
        padding-left: 12px;
        padding-right: 6px;
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-size: 16px;
        color: rgba(0,0,0,0.8);
        line-height: 24px;
        margin-top: 6px;
        cursor: pointer;
        border-radius: 5px;
        padding-top: 8px;
        padding-bottom: 8px;
      }
      .trainings-item:hover{
        background: #F2F2F2;
        border-radius: 6px;
      }
      .yellowActd{
        background: #FFF8F0;
        color: #FB9716;
      }
    }
    .main-right{
      display: block;
      padding: 0 20px;
      .content-tit{
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 36px;
        color: rgba(0,0,0,0.8);
        line-height: 50px;
        margin-bottom: 30px;
      }
      .contents{
        width: 805px;
        min-height: 451px;
        video{
          width: 805px;
          height: 451px;
          border-radius: 10px;
        }
      }
     
    }
  }
  
  
}
.w1200{
  width: 1200px;
  margin: 0 auto;
}

@media screen and (max-width: 750px) {
  .ResourceDetail{
    padding-top: 120px;
    .tabbox-goway{
      display: block;
      position: fixed;
      left: 0px;
      top: 80px;
      background-color: #fff;
      padding-left: 20px;
      line-height: 40px;
      width: 100%;
      z-index: 2;
    }
    .detail-header{
      display: none;
    }
    .detail-main {
      display: block;
      .main-left{
        display: none;
      }
      .main-right .contents {
        width: 100%;
        min-height: auto;
        padding-bottom: 0px;
        video{
          width: 99%;
          height: auto;
          border-radius: 10px;
        }
      }

    }
    .detail-main .main-right .content-tit {
        font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
        font-weight: normal;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.8);
        line-height: 30px;
        margin-bottom: 16px;
    }
    .contents h2{
      font-size: 18px;
    }

  } 
    
}

</style>
<style lang="scss">
.contents {
  img{
    display: inline-block;
    width: 800px;
    margin-bottom: 10px;
  }
  h1,h2,h3,h4,h5,li{
    margin-bottom: 15px;
    margin-top: 10px;
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    color: rgba(0,0,0,0.8);
  }
  h1,h2,h3{
    text-align: center;
    margin-top: 20px;
  }
  h1{
    font-size: 30px;
  }
  h2{
    font-size: 24px;
    margin-top: 25px;
  }
  h3{
    font-size: 20px;
  }
  h4{
    font-size: 18px;
  }
  p{
    margin-bottom: 16px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    line-height: 24px;
    font-size: 16px;
    color: rgba(0,0,0,0.8);
  }

}
.search .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: #fff;
}
.search .theme--light.v-text-field--filled > .v-input__control > .v-input__slot:hover {
  background: #fff!important;
}

.fixSarch .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
   background: #F9F9F9;
   border-radius: 27px;
   border: 1px solid #DFDFDF; 
}

.search  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-top: 0px;
    margin-bottom: 8px;
    display: none;
}

.ResourceDetail{
  .v-messages{
    display: none;
  }
  .v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
      align-items: stretch;
      min-height: 44px;
  }
  .v-text-field--full-width .v-input__prepend-outer, .v-text-field--full-width .v-input__prepend-inner, .v-text-field--full-width .v-input__append-inner, .v-text-field--full-width .v-input__append-outer, .v-text-field--enclosed .v-input__prepend-outer, .v-text-field--enclosed .v-input__prepend-inner, .v-text-field--enclosed .v-input__append-inner, .v-text-field--enclosed .v-input__append-outer {
      margin-top: 10px;
  }
  .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: #F9F9F9;
    border-radius: 27px;
    border: 1px solid #DFDFDF;
    width: 300px;
  }
}

.detail-right .v-text-field input {
    padding-left: 10px;
}
.detail-right .mdi-magnify::before {
    content: "\F0349";
    font-size: 0px;
    width: 24px;
    height: 24px;
    background: url('@a/images/resourceCenter/search.png') center top 1px no-repeat;
   background-size: 20px 20px;
}

@media screen and (max-width: 750px){

  .contents {
    img{
      display: inline-block;
      width: 100%;
      margin-bottom: 10px;
    }
    h1,h2,h3{
      font-size: 18px;
    }
  }
  ul {
    list-style: none; 
    padding: 0; 
  }
  
  li {
    margin-left: 20px;
  }

}
</style>
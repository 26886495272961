<template>
  <div class="headerPage" :class="[background,fixname]"  >
     <div class="header-center" :class="{'open': isOpenMenu }" >
        <!-- <img :src="loginUrl" alt="小五出海通" class="header-img" /> -->
        <span class="header-img"></span>
        <div class="header-right">
          <div class="header-item" :class="{'actived': activedUrl=='home'}">
            <a href="./" title="小五出海通">小五出海通</a>
          </div>
          <div class="header-item" :class="{'actived': activedUrl=='solution'}">
            <a href="./solution" title="解决方案">解决方案</a>
          </div>
          <div class="header-item" :class="{'actived': activedUrl=='resourceCenter'}">
            <a href="./resourceCenter" title="资源中心">资源中心</a>
          </div>
          <div class="header-item" :class="{'actived': activedUrl=='aboutUs'}">
            <a href="./aboutUs" title="资源中心">关于我们</a>
          </div>
          <div class="loginbox">
            <span class="login" :class="{'actived': activedUrl=='login'}" @click="toLogin">登录</span> 
            <span class="free" @click="toRegister">免费体验</span>
          </div>
        </div>
        <div class="burger" @click="fn">
          <v-icon v-if="!isOpenMenu" size="28">mdi-menu</v-icon>
          <v-icon v-else size="28">mdi-close</v-icon>
        </div>
       
     </div>
  </div>
</template>

<script>
import { isMobile } from '@/utils/index'
export default {
  name: 'headerPage',
  props: {
    activedUrl:{
      type:String,
      default:'home',
    },
    background:{
      type:String,
      default:'',
    }
  },
  components: {},
  data() {
    return {
      isOpenMenu: false, //小屏幕下是否显示菜单
      activeNav: 0,
      isSticky: false,
      scrollTop: 0,
      fixname:'',
    }
  },
  methods: {
    toRegister(){
      if(isMobile()){
        window.location.href = '/erwei'
      } else {
        window.location.href = 'https://cr.xiaowutube.com/register'
      }
    },
    toLogin(){
      if(isMobile()){
        window.location.href = '/erwei'
      } else {
       window.location.href = 'https://cr.xiaowutube.com/login'
      }
    },
    handleScroll() {
      this.scrollTop = document.documentElement
        ? document.documentElement.scrollTop
        : document.body.scrollTop
      if (this.scrollTop > 0) {
        this.fixname = 'bkWhite'
      } else{
        this.fixname = ''
      }
      this.isSticky = window.scrollY > 10
      this.isOpenMenu = false
    },
    fn() {
      this.isOpenMenu = !this.isOpenMenu

      if (this.isOpenMenu) {
        this.isSticky = true
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  watch: {},
  computed: {
    loginUrl(){
      let imgUrl = require('@a/images/header/header.png')
      if(this.activedUrl =='home'){
        imgUrl = require('@a/images/header/headerHome.png')
      }
      return imgUrl
    }
  },
  filters: {},
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style scoped lang="scss">
.headerPage {
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 99;
  
  .header-center{
    width: 1200px;
    margin: 0 auto;
    height: 80px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .header-img{
      display: inline-block;
      width: 120px;
      height: 30px;
      margin-top: 25px;
      background: url('@a/images/header/header.png') center no-repeat;
      background-size: 100% 100%;
    }
  }
  .header-right{
    width: 660px;
    display: flex;
    justify-content: space-between;
    padding-top: 23px;
    .header-item{
      height: 35px;
      font-weight: normal;
      font-size: 16px;
      color: rgba(0,0,0,0.8);
      line-height: 35px;
      text-align: center;
      a{
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        color: rgba(0,0,0,0.8);
      }
      
    }
    .actived {
      a{
        font-family: AlibabaPuHuiTiM;
        color: #FB9716;
      }
    }
    
    .login{
      display: inline-block;
      width: 112px;
      height: 35px;
      border-radius: 6px;
      border: 1px solid rgba(0,0,0,0.2);
      text-align: center;
      font-family: AlibabaPuHuiTiM;
      font-size: 16px;
      color: rgba(0,0,0,0.8);
      line-height: 35px;
      margin-right: 12px;
      cursor: pointer;
      
    }
    
    .login:hover{
      background: #F6F7F8;
    }
    .free{
      display: inline-block;
      width: 112px;
      height: 35px;
      background: linear-gradient( 135deg, #FFC82F 0%, #FF9715 100%), linear-gradient( 317deg, rgba(255,76,76,0.5) 0%, rgba(255,92,92,0) 100%);
      border-radius: 6px;
      text-align: center;
      font-family: AlibabaPuHuiTiM;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 35px;
      cursor: pointer;
    }
    .free:hover{
      background: linear-gradient( 135deg, #FFC82F 0%, #FF9715 50%), linear-gradient( 317deg, rgba(255,76,76,0.5) 0%, rgba(255,92,92,0) 100%);
    }
  }
}

.headerPage.resourceCenter{
  background: rgba(255,255,255,0.2);
}
.headerPage.aboutUs{
  background: rgba(255,255,255,0.2);
}

.headerPage.black{
  background: transparent;
  .header-img{
    display: inline-block;
    width: 120px;
    height: 30px;
    margin-top: 25px;
    background: url('@a/images/header/headerHome.png') center no-repeat;
    background-size: 100% 100%;
  }
  .header-right{
    .header-item{
      color: #fff;
      a{color: #fff;}
    }
    .actived {
      a{
        font-family: AlibabaPuHuiTiM;
        color: #FB9716;
      }
    }
    .login{
      color: #fff;
      background: rgba(255,255,255,0.2);
      border: 1px solid rgba(85,59,0,0.11);
    }
    
  }
}

.headerPage.solution{
  background: rgba(255,255,255,0.2);
}

.headerPage.bkWhite{
  background: rgba(255,255,255,1);
  .header-img{
    display: inline-block;
    width: 120px;
    height: 30px;
    margin-top: 25px;
    background: url('@a/images/header/header.png') center no-repeat;
    background-size: 100% 100%;
  }
  .header-right{
    .header-item{
      color: rgba(0,0,0,0.8);
      a{color: rgba(0,0,0,0.8);}
    }
    .actived {
      a{
        font-family: AlibabaPuHuiTiM;
        color: #FB9716;
      }
    }
    .login{
      color: rgba(0,0,0,0.8);
      background: rgba(255,255,255,0.2);
      border: 1px solid rgba(85,59,0,0.11);
    }
    .login:hover{
      background: #F6F7F8;
    }
  }
}




.burger {
  display: none;
}


@media screen and (max-width: 750px) {
  .headerPage  .header-center .header-img{
    display: inline-block;
    width: 100px;
    height: 25px;
    margin-top: 30px
  }
  .headerPage.black .header-img {
    display: inline-block;
    width: 100px;
    height: 25px;
    margin-top: 30px
  }
  
  .lang{
    .line{
      display: none !important;
    }
  }
  .nav_menu {
    display: none;
  }
  .burger {
    display: block;
    cursor: pointer;
    z-index: 20;
    line-height: 80px;
    padding-right: 20px;
  }
  .burger .v-icon {
    // color: #ffffff;
    color:rgba(0,0,0,0.8);
  }
  .headerPage .header-center{
    width: auto;
    padding-left: 20px;
  }
  .headerPage .header-right{
    display: none;
  }

  .nav_logo {
    max-width: 13vh;
  }
  
  .headerPage .open .header-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: right;
    position: absolute;
    right: 0;
    top: 80px;
    width: 100%;
    padding: 0 20px;
    background-color: #040404;
    /* opacity: 0; */
    -webkit-animation: slideDown 0.5s ease-out 0s 1 normal forwards;
    animation: slideDown 0.5s ease-out 0s 1 normal forwards;
    .loginbox{
      border-top: 1px solid #EFEFEF;
      width: 100%;
      padding: 15px 10px 20px;
      text-align: center;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      span{
        display: inline-block;
        margin: 0;
        margin-bottom: 0px;
      }
      .login{
        border: 0px;
        background-color: transparent;
        color: rgba(0, 0, 0, 0.8);
        line-height: 56px;
        height: 56px;
      }
      .login.actived{
        color: #FB9716;
      }
      span.free{
        border: 0px;
        background: transparent;
        color: rgba(0, 0, 0, 0.8);
        line-height: 56px;
        height: 56px;
      }
      
    }
  }
  .black .open .header-right .loginbox{
    span.login, span.free{
      color: #fff;
    }
  }
  .bkWhite .open .header-right .loginbox{
    span.login, span.free{
      color: rgba(0, 0, 0, 0.8);
    }
  }
  .headerPage .header-right .header-item{
    height: 56px;
    line-height: 56px;
  }
  .headerPage .header-right .header-item a{
    display: inline-block;
    height: 56px;
    line-height: 56px;
  }

  .headerPage.black .open  {
    background-color: #040404;
  }
  .headerPage.black .open .header-right .loginbox {
    border-top: 1px solid #737171;
  }
  .headerPage.bkWhite .open  {
    background-color: #fff;
  }
  .headerPage.bkWhite .open .header-right .loginbox {
    border-top: 1px solid #EFEFEF;
  }
  
  .headerPage.solution .open .header-right{
    background: rgba(255,255,255,1);
  }
  .headerPage.resourceCenter .open .header-right{
    background: rgba(255,255,255,1);
  }
  .headerPage.aboutUs .open .header-right{
    background: rgba(255,255,255,1);
  }
  .headerPage.resourceCenter{
    background: rgba(255, 255, 255, 1);
  }
  .headerPage.aboutUs{
    background: rgba(255,255,255,1);
  }
  .headerPage.solution{
    background: rgba(255,255,255,1);
  }
  .black .burger .v-icon{
      color: #ffffff;
  }
  // 白底
  .bkWhite .burger .v-icon{
    color: rgba(0,0,0,0.8);
  }
  .headerPage.bkWhite .open .header-right{
    background: rgba(255,255,255,1);
  }

  
  /* 导航列表 */
  @-webkit-keyframes slideDown {
    from {
      top: -55%;
      opacity: 0;
    }
    to {
      top: 79px;
      opacity: 1;
    }
  }
  @keyframes slideDown {
    from {
      top: -55%;
      opacity: 0;
    }
    to {
      top: 79px;
      opacity: 1;
    }
  }
}

</style>
<style lang="scss">
.bigTitle{
  height: 55px;
  font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
  font-weight: normal;
  font-size: 40px;
  color: rgba(0,0,0,0.8);
  line-height: 55px;
  text-align: center;
  font-style: normal;
  margin-bottom: 60px;
  margin-top: 120px;
  position: relative;
}
.w1200{
  width: 1200px;
}
@media screen and (max-width: 750px) {
  .bigTitle{
      height: 55px;
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 28px!important;
      color: rgba(0,0,0,0.8);
      line-height: 55px;
      text-align: center;
      font-style: normal;
      margin-bottom: 40px;
      margin-top: 60px;
      position: relative;
    }
  .w1200{
    width: auto!important;
  }
}

</style>


<template>
  <div class="foot">
    <div class="foot-yellow" v-show="!erShow">
      <div class="bigTitle" title="立即免费试用">立即免费试用</div>
      <div><span class="free" @click="toRegister">免费体验<img src="@a/images/foot/go.png" alt=""/></span></div>
    </div>
    <div class="foot-bk">
      <div class="pubCon">
        <h1 class="logo">
          <img src="@a/images/集团logo@2x.png" alt="" />
        </h1>
        <div class="flex jc_sb al_end bottom">
          <div class="left">
            <div class="flex al_center bop">
              <img src="@a/images/foot/电话@2x.png" alt="" />
              <div class="box">
                <div >联系电话</div>  
                <div class="text">0571-88308005 / 18035631407</div>
              </div>
            </div>
            <div class="flex al_center bop">
              <img src="@a/images/foot/地址@2x.png" alt="" />
              <div class="box">
                <div>联系地址</div>
                <div class="text">
                   浙江省杭州市拱墅区祥园路28号6幢301室
                </div>
              </div>
            </div>
          </div>
          <div class="right flex flex-end">
            <div class="weixin">
              <div class="weixin-box">
                <img src="@a/images/foot/weixin1.png" alt=""/>
              </div>
              <div class="weixin-des">关注官方公众号</div>
              <div class="weixin-text">时刻掌握海外最新资讯</div>
            </div>
            <div class="weixin">
              <div class="weixin-box">
                <img src="@a/images/foot/weixin2.png" alt=""/>
              </div>
              <div class="weixin-des">扫码添加运营专家</div>
              <div class="weixin-text">帮你提供定制化最优方案</div>
            </div>
            
          </div>
        </div>
      </div>
      <div class="tip">
        Copyright © 2024 杭州小五和她的兄弟们科技有限公司 &nbsp; &nbsp; &nbsp;<a href="http://beian.miit.gov.cn/ ">浙ICP备20027697号-2</a>
      </div>
    </div>
   
  </div>
</template>

<script>
import { isMobile } from '@/utils/index'
export default {
  name: 'FootCon',
  props: {},
  components: {},
  data() {
    return {
      icons: [
        {
          img: require('@a/images/foot/facebook@2x.png'),
          url: 'https://www.facebook.com/xiaowubrother/',
        },
        {
          img: require('@a/images/foot/X@2x.png'),
          url: 'https://twitter.com/xiaowubrother',
        },
        { img: require('@a/images/foot/ins@2x.png') },
        {
          img: require('@a/images/foot/YouTube@2x.png'),
          url: 'https://www.youtube.com/@user-yl8dn9vk4e',
        },
        { img: require('@a/images/foot/tiktok@2x.png') },
      ],
      erShow:false,
    }
  },
  methods: {
    toRegister(){
      if(isMobile()){
        window.location.href = '/erwei'
      } else {
       window.location.href = 'https://cr.xiaowutube.com/register'
      }
    },
  },
  mounted() {
     
    if(this.$route.path =="/erwei"){
      this.erShow = true
    }

  },
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.foot {
  .foot-yellow{
    height: 250px;
    text-align: center;
    background: url('@a/images/foot/footfree.png') center no-repeat;
    padding-top: 10px;
    background-size: 100% 100%;
    .bigTitle{
      margin-top: 50px;
      margin-bottom: 25px;
      font-weight: 300;
      font-family: AlibabaPuHuiTi, 'AlibabaPuHuiTiM';
    }
    .free{
      display: inline-block;
      width: 160px;
      height: 48px;
      background: linear-gradient( 135deg, #FFC82F 0%, #FF9715 100%), linear-gradient( 317deg, rgba(255,76,76,0.5) 0%, rgba(255,92,92,0) 100%);
      border-radius: 6px;
      text-align: center;
      font-family: AlibabaPuHuiTiM;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 48px;
      cursor: pointer;
      img{
        display: inline-block;
        width: 26px;
        height: 26px;
        vertical-align: -8px;
        transition: all 0.35s;
        position: relative;
        left: 0px;
      }
    }
    .free:hover img{
      left: 8px;
    }
    .free:hover{
      background: linear-gradient( 135deg, #FFC82F 0%, #FF9715 50%), linear-gradient( 317deg, rgba(255,76,76,0.5) 0%, rgba(255,92,92,0) 100%);
    }
  }
  .foot-bk{
    background: #1E2228;
    color: #fff;
  }
  .logo {
    padding: 35px 0 25px;
    img {
      width: 130;
      height: 40px;
    }
  }
  .bottom {
    padding-bottom: 40px;
  }
  .jc_sb{
    justify-content: space-between;
  }
  .left {
    .bop {
      padding: 10px 0;
      img {
        margin-right: 20px;
        width: 30px;
        height: 30px;
      }
      .box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div {
          font-family: AlibabaSans_Medium;
          font-size: 14px;
        }
      }
      .text {
        font-family: AlibabaSans_Light !important;
        color: #fff;
      }
    }
  }
  .right {
     display: flex;
    .weixin{
      margin-right: 16px;
      text-align: center;
      .weixin-box{
        width: 120px;
        height: 104px;
        position: relative;
        text-align: center;
        img{
          display: inline-block;
          width: 100px;
          height: 104px;
        }
      }
      .weixin-des{
        height: 17px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 17px;
        margin-top: 10px;
      }
      .weixin-text{
        font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
        font-weight: 400;
        font-size: 12px;
        color: rgba(255,255,255,0.6);
        line-height: 16px;
      }
    }
 
  }
  .flex-end{
    justify-content: end;
  }
  .tip {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    border-top: 1px solid rgba(255,234,215,0.2);
    font-family: AlibabaSans, AlibabaSans;
    font-weight: 300;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 18px;
    padding: 12px 0;
    a{
      font-family: AlibabaSans, AlibabaSans;
      color: #FFFFFF;
      font-size: 12px;
    }
  }

}
@media screen and (max-width: 750px) {
  .foot .tip {
    width: auto;
  }
  .foot .right .weixin{
    margin-right: 0px;
  }
  .foot .right .weixin:nth-child(1){
    margin-right: 30px;
  }

  .foot .right .weixin .weixin-text {
    font-size: 10px;
  }
  .foot .logo{
    padding-left: 20px;
  }
  .foot .jc_sb{
    display: block;
    padding-left: 20px;
    padding-right: 20px;
  }
  .foot .flex-end{
    justify-content: center;
    padding-top: 36px;
  }
  .foot .tip a{
    display: block;
  }
  .foot .foot-yellow .bigTitle{
    margin-bottom: 10px;
    margin-top: 30px;
  }
  .foot .foot-yellow {
    height: 180px;
    text-align: center;
    background: url('@a/images/foot/footfree.png') center no-repeat;
    padding-top: 1px;
    background-size: 1200px 220px;
    .free{
      font-size: 16px;
    }
  }
    
}
</style>

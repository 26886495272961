<template>
  <div class="ResourceCenter">
    <Header :activedUrl="activedUrl" :background="background"></Header>
    <div class="resouceSearch">

      <div class="search" :class="{'fixSarch':scrollTop > 0}">
        <div class="searc-box">
          <span class="search-tit">资源中心</span>
          <span class="search-inline">
            <v-form @submit.prevent>
            <v-text-field
              class="search-texts"
              v-model="searchValue"
              @keyup.enter="getList"
              prepend-inner-icon="mdi-magnify"
              :rules="[textLength]"
              placeholder="搜索"
              filled
              rounded
              clearable
            ></v-text-field>
            <div class="search-btn" @click="getList">搜索</div>
            </v-form>
          </span>
        </div>
        
      </div>

      <div class="w1200 tabbox-goway" @click="goBack">
        
        <img src="@a/images/resourceCenter/goway.png" alt="小五出海返回上一页" class="goway" />
        返回上一页
      </div>
      <div class="w1200 tabbox-des">找到与“{{searchtext}}”相关结果共 {{ list.length }} 条</div>
      <div class="w1200 numb-box" v-show="list && list.length > 0" >
        <div class="numb-item" v-for="(item,i) in list" :key="i" @click="toDetail(item)">
          <div class="numb-tit" v-html="item.titleHtml"></div>
          <div class="numb-des" v-show="!item.img">{{ item.description }}</div>
          <div class="des-img" v-show="item.type=='train_activity'">
            <img :src="item.thumbnail" v-show="item.thumbnail" /> 
          </div>
        </div>
      </div>
      <div class="nolist" v-show="!list || list.length === 0">
        <img src="@a/images/resourceCenter/no.png" alt="暂无数据" class="goway" />
        <div class="nolist-des">暂无搜索结果</div>
      </div>

    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import Header from '@v/header/index'
import Foot from '@v/foot/index'
import { search } from '@/api'
import { toastInfo } from '@/utils/prompt'
export default {
  name: 'ResourceSearch',
  props: {},
  components: {
    Header, Foot
  },
  data() {
    return {
      activedUrl:'resourceCenter',
      background:'resourceCenter',
      searchValue:'',
      list:[],
      scrollTop:0,
      tabValue:'numb',
      searchtext:'',
      startIs:true,
    }
  },
  methods: {
   textLength(value){
     if(value && value.length >20 ){
      toastInfo('输入搜索内容,不可大于20字符！')
      this.searchValue = value.slice(0,20)
      return false
     }else{
      return true
     }
   },
    goBack(){
      this.$router.go(-1)
    },

    getList() {
      if((this.searchValue =='' || !this.searchValue) && !this.startIs ){
        toastInfo('请先输入搜索内容！')
      }else{
        this.startIs = false
        this.searchtext = this.searchValue
        search({title:this.searchValue})
        .then(({ data }) => {
          
          let dataList = data
          dataList.map( item => {
            item.titleHtml = item.title.replace(new RegExp(this.searchValue, 'gi'), `<span class="highlight">${this.searchValue}</span>`);
          })
          
          this.list = dataList
        })
        .catch(error => error)
      }
    },
    
    toDetail(row){
      if(row.type=='train_activity'){
        window.location.href = '/resourceDetail?tabValue=trainings&itemTitle='+row.title+'&id='+row.id;
      } else {
        window.location.href = '/resourceDetail?tabValue=numbs&itemTitle='+row.title+'&id='+row.id;
      }
    }

  },
  mounted() {
    // this.searchValue = this.$route.query.value
    this.getList()
  },
  beforeDestroy() {
  },
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.ResourceCenter{
  overflow-x: scroll;
}
.resouceSearch{
  padding-top: 150px;
  min-height: 800px;
  padding-bottom: 60px;
  background-image:  url('@a/images/resourceCenter/resourceCenter.png');
  background-size:100% 700px;
  .search{
    .searc-box{
      width: 1200px;
      margin: 0 auto;
      .search-tit{
        display: none;
      }
    }
    text-align: center;
    span.search-inline{
      display: inline-block;
      width: 450px;
      position: relative;
      padding-right: 30px;
    }
    .search-btn{
      position: absolute;
      right: -10px;
      top: 0px;
      width: 60px;
      line-height: 56px;

      font-family: AlibabaPuHuiTiM;
      font-size: 16px;
      color: #FF9715;
      background: #fff;
      border-radius: 0 30px 30px 0;
      padding-left: 10px;
      text-align: left;
      cursor: pointer;
    }
    .search-btn::before{
      content: "";
      left: -2px;
      top: 19px;
      position: absolute;
      width: 1px;
      height: 20px;
      border: 1px solid #E8E9EC;

    }
   
  }
  
  .search-text{
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0,0,0,0.4);
    line-height: 22px;
    text-align: center;
    margin-bottom: 60px;
    span{
      color: #FB9716;
    }
  }

  .tabbox-goway{
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0,0,0,0.8);
    line-height: 22px;
    margin: 0 auto;
    margin-top: 60px;
    cursor: pointer;
    img{
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: -4px;
    }
  }
  .tabbox-des{
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0,0,0,0.6);
    line-height: 22px;
    text-align: left;
    margin: 0 auto;
    margin-bottom: 30px;
    padding-top: 16px;
    border-top: 1px solid #EFEFEF;
    margin-top: 16px;
  }
  .numb-box{
    margin: 0 auto;
  }
  .numb-item{
    margin-top: 10px;
    padding: 10px 20px 20px;
    cursor: pointer;
    .numb-tit{
      height: 33px;
      font-family: AlibabaPuHuiTiM;
      font-size: 22px;
      color: rgba(0,0,0,0.8);
      line-height: 33px;
      text-align: left;
      margin-bottom: 10px;
    }
    .numb-des{
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 16px;
      color: rgba(0,0,0,0.8);
      line-height: 22px;
      text-align: left;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .des-img{
      img{
        display: block;
        width: 200px;
        height: 113px;
        border-radius: 3px;
      }
    }
  }
  .numb-item:hover{
    background: #F2F2F2;
    border-radius: 10px;
  }
  .nolist{
    margin: 0 auto;
    padding-top: 150px;
    text-align: center;
    padding-bottom: 100px;
    img{
      display: inline-block;
      width: 150px;
    }
    .nolist-des{
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0,0,0,0.6);
      line-height: 20px;
      text-align: center;
      margin-top: 10px;
    }
  }
}


@media screen and (max-width: 750px) {
  .resouceSearch .search .searc-box {
    width: auto;
    margin: 0 auto;
  }
  .resouceSearch .search span.search-inline {
    width: auto;
  }
  .resouceSearch .tabbox-goway{
    margin: 0 20px;
    margin-top: 40px;
  }
  .resouceSearch .tabbox-des{
    padding-left: 20px;
  }

  .resouceSearch .numb-item{
    cursor: pointer;
    margin: 40px 20px 0 20px;
  }
  .resouceSearch .numb-item .numb-tit {
    height: auto;
    font-family: AlibabaPuHuiTiM;
    font-size: 22px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 32px;
    text-align: left;
    margin-bottom: 10px;
  }

}

</style>
<style lang="scss">
span.highlight{
  font-size: 24px;
  color: #FB9716;
  font-family: AlibabaPuHuiTiM;
}
.search .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
    background: #fff;
}
.search .theme--light.v-text-field--filled > .v-input__control > .v-input__slot:hover {
  background: #fff!important;
}
.fixSarch .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
   background: #F9F9F9;
   border-radius: 27px;
   border: 1px solid #DFDFDF; 
}

.search  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-top: 0px;
    margin-bottom: 8px;
    display: none;
}
.search .v-text-field input {
  padding-left: 10px;
  font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
  color: rgba(0, 0, 0, 0.8);
}
</style>
export const required = value => {
  if (value === null || value === undefined || value === '') {
    return 'This is a required field'
  }

  if (Array.isArray(value) && value.length === 0) {
    return 'This is a required field'
  }

  return true
}

export const isFunction = fun => typeof fun === 'function'
export function asynchronousReturn(fun) {
  if (isFunction(fun)) {
    return new Promise((resolve, reject) => {
      const data = fun()
      if (data) resolve(data)
      else reject(data)
    })
  }

  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject(false)
}

export function aosIndex(index) {
  if (index % 2 === 0) return 'fade-down-right'
  if (index % 2 !== 0) return 'fade-down-left'
}


export function urlFill (url) {
  if(url.includes('http')){
    return url
  }else{
    return `https://${url}`
  }
}

export function  isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return /android|avantgo|blackberry|iemobile|ipad|iphone|ipod|opera mini|opera mobi|palm|pocket|psp|series(4|6)0|symbian|windows ce|windows phone|xda|xiino/i.test(userAgent)
 }
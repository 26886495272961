<template>
  <div class="homePage">
    <div class="technology">
      <Header :activedUrl="activedUrl" :background="backgroud"></Header>
      <Slides></Slides>
    </div>
    <div class="toSeeServe">
      <div class="bigTitle " title="为创作者提供全方位的出海增长服务">为创作者提供全方位的出海增长服务</div>
      <div class="bigTitle h5Title" title="为创作者提供全方位的出海增长服务">为创作者提供全方位的<br>出海增长服务</div>

      <div class="toSeeTab-box">
        <div class="toSeeTab">
          <div v-for="(item,i) in toSeeServe" :key="'see'+i" class="tabItem" :class="{'yellowAct':item.title == currentServe.title}"  @click="handerServe(i)">
            <img :src="item.icon" v-show="item.title !==currentServe.title">
            <img :src="item.iconYel" v-show="item.title ==currentServe.title">
            {{item.title }}
          </div>
        </div>
      </div>

      <div class="w1200 toSeeServes">
        <div class="toSeeServe-left">
          <div class="toSee-serve-item " v-for="(item,index) in toSeeServe" :key="index" @click="handerServe(index)" :class="{'toSee-serves':item.title ==currentServe.title}">
            <div class="toSee-serves-tit">
              <img :src="item.icon" v-show="item.title !==currentServe.title">
              <img :src="item.iconYel" v-show="item.title ==currentServe.title">
              
              {{item.title}}
            </div>
            <div class="toSee-serves-text">{{item.detailDes}}</div>
           
            
          </div>
        </div>
        <div class="toSeeServe-right">
          <div class="toSee-serves-des">{{currentServe.detailDes}}</div>
          <video id="myVideo" autoplay="" loop="" muted="" playsinline=""  v-show="currentServe.videoUrl"  :key="currentServe.id" style="width: 100%;height:100%;border-radius: 20px;">
            <source :src="currentServe.videoUrl" :key="currentServe.id" :datas="currentServe.id" type="video/mp4">
          </video>

        </div>
      </div>
    </div>
    
    <div class="collaborationProcess">
      <div class="bigTitle" title="合作流程">合作流程</div>
      <div class="process-w1480">
        <img src="@a/images/home/partall.png" alt="小五出海"  />
      </div>
      
      <img src="@a/images/home/h5Part.png" class="h5Part">
    </div>
    
    <Partner></Partner>

    <div class="problem">
      <div class="bigTitle" title="常见问题">常见问题</div>
      <div class="w1200 problem-main">
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(item,i) in problemList"
            :key="i"
          >
            <div >
              <v-expansion-panel-header >
                {{ item.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.text }}
              </v-expansion-panel-content>
            </div>
          </v-expansion-panel>
        </v-expansion-panels>

      </div>
      <div class="problem-down" title="常见问题" @click="handerProble">
        {{ isOpen ? '收起':'查看更多' }}
        <v-icon
          v-if="isOpen"
          size="28"
          class="me-2"
          color="primary"
        >mdi-chevron-up
        </v-icon>
        <v-icon
          v-else
          size="28"
          class="me-2"
          color="primary"
        >mdi-chevron-down
        </v-icon>
      </div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import Header from '@v/header/index'
import Foot from '@v/foot/index'
import Slides from './components/slides'
import Partner from './components/partner'
import {problemList, toSeeServe } from './home'
export default {
  name: 'homeIndex',
  props: {},
  components: {
    Header, Foot,Slides,Partner
  },
  data() {
    return {
      activedUrl:'home',
      backgroud:'black',
      toSeeServe:[],
      currentServe:{},
      problemList:[],
      PartList:[],
      isOpen:false,
    }
  },
  
  methods: {
    handerServe(value){
      this.currentServe = this.toSeeServe[value]
    },
    handerProble(){
      this.problemList= this.isOpen ? problemList.slice(0,4) : problemList
      this.isOpen= this.isOpen ? false : true
      
    },
  },
  mounted() {
    this.toSeeServe = toSeeServe
    this.handerServe(0)
    this.problemList = problemList.slice(0,4)
  },
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">
.homePage{
  overflow-x: scroll;
  .technology{
    width: 100%;
    background: linear-gradient( 135deg, #331D00 0%, #130A00 45%, #000000 100%);
    padding-top: 130px;
    padding-bottom: 90px;
  }
  .toSeeServe{
    padding-top: 127px;
    padding-bottom: 112px;
    background: linear-gradient(17deg, rgba(255, 158, 81, 0) 0%, rgba(255, 158, 81, 0) 19%, rgba(255, 253, 248, 1) 100%), linear-gradient(143deg, rgba(255, 242, 75, 0.04) 0%, rgba(255, 252, 75, 0.03) 75%, rgba(255, 253, 75, 0) 100%), #FFFFFF;
    .bigTitle{
      position: relative;
      background: url('@a/images/home/toseeTit.png') left 420px top 0px no-repeat;
      line-height: 70px;
      height: 70px;
      background-size: 237px 70px;
      width: 700px;
      margin: 0 auto;
      margin-top: 0px;
      margin-bottom: 110px;
    }

    .h5Title, .toSeeTab-box{
      display: none;
    }
    
    .toSeeServes{
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .toSeeServe-left{
        padding-left: 20px;
        width: 440px;
        margin-right: 43px;
        
        .toSee-serve-item{
          padding-left: 24px;
          font-family: AlibabaPuHuiTiM;
          font-size: 24px;
          color: rgba(0,0,0,0.8);
          line-height: 33px;
          margin-top: 40px;
          cursor: pointer;
          img{
            display: inline-block;
            width: 26px;
            height: 26px;
            margin-right: 12px;
            vertical-align: -4px;
          }
          .toSee-serves-text{
            display: none;
          }
        }
        .toSee-serves-tit{
            font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
            font-weight: normal;
            font-size: 24px;
            color: rgba(0,0,0,0.8);
            line-height: 33px;
            margin-bottom: 14px;
            img{
              display: inline-block;
              width: 26px;
              height: 26px;
              margin-right: 12px;
            }
          }
        .toSee-serves{
          width: 400px;
          min-height: 143px;
          background: linear-gradient( 47deg, #FFFCEF 0%, #FFFFFF 100%);
          box-shadow: 0px 10px 20px 0px rgba(29,17,0,0.06), inset 2px -2px 0px 0px #FFFFFF, inset -1px 1px 0px 0px #FFF5E9;
          border-radius: 12px;
          backdrop-filter: blur(6px);
          padding: 24px 28px;
          box-sizing: border-box;
          .toSee-serves-tit{
            font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
            font-weight: normal;
            font-size: 24px;
            color: #FB9716;
            line-height: 33px;
            margin-bottom: 14px;
            img{
              display: inline-block;
              width: 26px;
              height: 26px;
              margin-right: 12px;
            }
          }
          .toSee-serves-text{
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: 400;
            font-size: 16px;
            color: #626262;
            line-height: 22px;
            display: block;
          }
        }
        
        
      }
      .toSeeServe-right{
        flex: 1;
        .toSee-serves-des{
          display: none;
        }
        img{
          display: block;
          width: 792px;
          height: 500px;
        }
        video{
          width: 792px;
          height: 500px;
        }
      }
    }

  }

  .collaborationProcess{
    background: url('@a/images/home/collaborationProcess.png') center no-repeat;
    background-size: 100% 100%;
    padding-bottom: 95px;
    padding-top: 1px;
    .bigTitle{
      margin-top: 100px;
    }
    .process-w1480{
      max-width: 1630px;
      height: 400px;
      margin: 0 auto;
      img{
        width: 100%;
      }
    }
    .h5Part{
      display: none;
    }
  }

  
  .problem{
    padding-bottom: 80px;
    .problem-main{
      margin: 0 auto;
    }
    .problem-down{
      height: 33px;
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 20px;
      color: rgba(0,0,0,0.6);
      text-align: center;
      margin: 0 auto;
      cursor: pointer;
      margin-top: 24px;
      transition: all 0.5s;
      .v-icon.v-icon {
        vertical-align: -5px;
      }

    }
  }

}


@media screen and (max-width: 750px) {
  .homePage{
    .toSeeServe {
      padding-top: 60px;
      padding-bottom: 40px;
      .bigTitle {
        position: relative;
        background: url('@a/images/home/toseeTit.png') right 100px top 40px no-repeat;;
        line-height: 40px;
        height: 80px;
        background-size: 158px 45px;
        width: 360px;
        margin: 0 auto;
        margin-top: 0px;
        margin-bottom: 20px;
        display: none;
      }
      .h5Title{
        display: block;
      }
      .toSeeTab-box{
        display: block
      }
      .toSeeTab-box{
        width: 100%;
        overflow-x: auto;
        display: block;
        -ms-overflow-style: none;  /* IE 10+ */
        scrollbar-width: none;  
      
      }
      .toSeeTab-box::-webkit-scrollbar {
        display: none;
      }

      .toSeeTab{
        margin: 0 auto;
        padding: 0 10px;
        display: block;
        white-space: nowrap;
        margin-bottom: 20px;
        margin-top: 20px;
        width: 1050px;
        //  width: auto;
        border-bottom: 1px solid rgba(31,35,41,.15);
        .tabItem{
          display: inline-block;
          padding: 0 20px;
          font-family: AlibabaPuHuiTiM;
          font-size: 18px;
          color: rgba(0,0,0,0.8);
          line-height: 50px;
          border-bottom: 2px solid transparent;
          img{
            display: inline-block;
            width: 24px;
            vertical-align: -6px;
          }
        }
        .yellowAct{
          color: #FB9716;
          border-bottom: 2px solid #FB9716;
        }
      }
      
      .toSeeServes {
        display: block;
        .toSeeServe-left{
          display: none;
          padding-left: 20px;
          width: auto;
          padding-right: 20px;
          margin-right: 0px;
          .toSee-serves{
            width: auto;
            height: auto;
            background: linear-gradient(47deg, #FFFCEF 0%, #FFFFFF 100%);
            box-shadow: 0px 10px 20px 0px rgba(29, 17, 0, 0.06), inset 2px -2px 0px 0px #FFFFFF, inset -1px 1px 0px 0px #FFF5E9;
            border-radius: 12px;
            -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
            padding: 24px 28px;
            box-sizing: border-box;
            .toSee-serves-text {
              font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
              font-weight: 400;
              font-size: 16px;
              color: #626262;
              line-height: 22px;
              display: block;
              margin-bottom: 10px;
            }
           
            
          }
          
        }
        .toSeeServe-right{
          padding: 0 20px;
          .toSee-serves-des{
            display: block;
            font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
            font-weight: 400;
            font-size: 16px;
            color: #626262;
            line-height: 22px;
            display: block;
            margin-bottom: 26px;
            word-break: break-all;
          }
        } 

      }
     
    }
    .collaborationProcess{
      padding-bottom: 40px;
      padding-left: 20px;
      padding-right: 20px;
      .bigTitle {
          margin-top: 30px;
          margin-bottom: 10px;
      }
      .process-w1480{
        display: none;
        height: auto;
        overflow-x: scroll;
        -ms-overflow-style: none;  /* IE 10+ */
        scrollbar-width: none;  
        img{
          width: 900px;
        }
      }
      .process-w1480::-webkit-scrollbar {
        display: none;
      }
      .h5Part{
        display: block;
        width: 100%;
      }
    }

    .problem{
      padding-bottom: 40px;
      padding-left: 20px;
      padding-right: 20px;
      .problem-down{
        margin-top: 0px;
        font-size: 16px;
        
      }
    }
    

  }

}
</style>
<style  lang='scss'>
.problem-main{

  .v-expansion-panel-header {
    justify-content: space-between;
    text-align: left;
  }

  .v-expansion-panel::before{
    box-shadow: none;
  }
  .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
    border-color: #fff;
  }
  .theme--light.v-expansion-panels .v-expansion-panel{
    background: #F9FAFA;
    border-radius: 12px;
    margin-bottom: 20px;
  }
  
  .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
    margin-top: 0px;
  }
  .v-expansion-panel-header{
    // line-height: 1;
    line-height: 32px;
    font-family: AlibabaPuHuiTiM;
    font-size: 20px;
    color: rgba(0,0,0,0.8);
    outline: none;
    padding: 24px 24px;
    width: 100%;
    display: flex;
  }

  .v-expansion-panel--active .v-expansion-panel-header{
    color:#FB9716 ;
  }
   .theme--light.v-expansion-panels .v-expansion-panel--active .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
    transform: rotate(-180deg);
  }

  .v-expansion-panel-content__wrap {
    color: rgba(0,0,0,0.6);
    font-size: 16px;
    font-weight: 300;
    padding-bottom: 24px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    padding-left: 24px;
  }
}


#myVideo::-webkit-media-controls-play-button,
#myVideo::-webkit-media-controls-start-playback-button {
  display: none;
}
.toSeeServe-left, .toSeeServe-right{
   /* 隐藏整个控制条 */
   video::-webkit-media-controls-panel {
        display: none;
    }
 
    /* 也可以单独隐藏进度条和其他按钮 */
    video::-webkit-media-controls-timeline {
        display: none!important;
    }
 
    video::-webkit-media-controls-timeline-container {
        display: none!important;
    }
 
    video::-webkit-media-controls-mute-button {
        display: none!important;
    }
 
    video::-webkit-media-controls-play-button {
      display: none!important;
    }
 
    video::-webkit-media-controls-volume-slider {
      display: none!important;
    }
 
    video::-webkit-media-controls-seek-back-button {
      display: none!important;
    }
 
    video::-webkit-media-controls-seek-forward-button {
      display: none!important;
    }
 
    video::-webkit-media-controls-fullscreen-button {
        display: none;
    }
 
    video::-webkit-media-controls-rewind-button {
        display: none;
    }
 
    video::-webkit-media-controls-return-to-realtime-button {
        display: none;
    }
 
    video::-webkit-media-controls-toggle-closed-captions-button {
        display: none;
    }
        /* 针对Safari的样式 */
     video::-webkit-media-controls {
        display: none !important;
    }
 
    /* 针对Safari中的进度条 */
    video::-webkit-progress-bar {
        display: none !important;
    }
 
    /* 针对Safari的载入圈 */
    video::-webkit-loading-bar {
        display: none !important;
    }

}

@media screen and (max-width: 750px) {
  .problem-main .v-expansion-panel-header {
    line-height: 24px;
    font-family: AlibabaPuHuiTiM;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.8);
    outline: none;
    padding: 16px 14px;
    width: 100%;
    display: flex;
  }
  .problem-main .v-expansion-panel-content__wrap {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 300;
    padding-bottom: 16px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    padding-left: 14px;
  }
}
</style>





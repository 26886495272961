<template>
  <div class="partner-page">
    <div class="partner-tit">合作伙伴</div>

    <div class="partner-mains">
      <div class="partner-boxs">
        <div class="prtner-item" v-for="(item,i) in PartList" :key="i" :class="{'partner-yellow':i == indexs}" @mouseenter="handerIndex(i)" @mouseleave="resumeScroll" @click="handerIndex(i)">
          <div class="item-top">
            <img :src="item.img" >
            <div class="name">{{ item.name }}</div>
            <div class="titText">{{ item.titText }}</div>
          </div>
          <div class="item-des">{{ item.des }}</div>
        </div>
      </div>
    </div>

    <div class="partner-imgs">
      <div class="partner-imgsleft">
        <div class="part-tit">
          <!-- 官方合作伙伴 -->
          <img src="@a/images/home/partTitle1.png" alt="小五出海"  />
        </div>
        <img src="@a/images/home/pl1.png" alt="小五出海"  class="partleft1" />
        <img src="@a/images/home/pl2.png" alt="小五出海"  class="partleft2" />
        <img src="@a/images/home/pl3.png" alt="小五出海"  class="partleft3" />
        <img src="@a/images/home/pl4.png" alt="小五出海"  class="partleft4" />
      </div>
      <div class="partner-imgsright">
        <div class="part-tit">
          <!-- 其他合作平台 -->
          <img src="@a/images/home/partTitle2.png" alt="小五出海"   />
        </div>
        <img src="@a/images/home/pr1.png" alt="小五出海"  class="partrt partrt1" />
        <img src="@a/images/home/pr2.png" alt="小五出海"  class="partrt partrt2" />
        <img src="@a/images/home/pr3.png" alt="小五出海"  class="partrt partrt3" />
        <img src="@a/images/home/pr4.png" alt="小五出海"  class="partrt partrt4" />
        <img src="@a/images/home/pr5.png" alt="小五出海"  class="partrt partrt5" />
        <!-- <img src="@a/images/home/pr6.png" alt="小五出海"  class="partrt partrt6" /> -->
        <div class="h5imgbox">
          <div class="h5imgs">
           <img src="@a/images/home/h5img1.png" alt="小五出海" />
           <img src="@a/images/home/h5img2.png" alt="小五出海" />
          </div>
          <div class="h5imgs">
            <img src="@a/images/home/h5img3.png" alt="小五出海" />
            <img src="@a/images/home/h5img5.png" alt="小五出海" />
          </div>
          <div class="h5imgs">
            <img src="@a/images/home/h5img4.png" alt="小五出海" />
            <img src="@a/images/home/h5img6.png" alt="小五出海" style="opacity: 0;" />
          </div>
        </div>

        
      </div>
    </div>
      
  </div>
</template>

<script>
import {PartList } from '../home'
export default {
  name: 'partner-page',
  props: {},
  components: {
 
  },
  data() {
    return {
      PartList:[],
      intervalId: null,
      indexs:0,
    }
  },
  methods: {
    handerSlider(value){
      this.actived = value
    },
    startScroll() {
      if (this.intervalId) return;
      this.intervalId = setInterval(() => {
        if (this.indexs ==2) {
          this.indexs = 0
        } else {
          this.indexs = this.indexs +1
        }
      }, 5000); // 滚动速度，可以根据需要调整
      
    },
    pauseScroll() {
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
    resumeScroll() {
      this.startScroll();
    },
    handerIndex(i){
      this.indexs = i
      this.pauseScroll()
    },
  },
  mounted() {
    this.PartList =PartList
    this.startScroll()
  },
  watch: {},
  computed: {},
  filters: {},
}
</script>

<style scoped lang="scss">

.partner-page{
  max-width: 1400px;
  min-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 160px;
  padding-bottom: 56px;
  .partner-tit{
    font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
    font-weight: normal;
    font-size: 40px;
    color: rgba(0,0,0,0.8);
    line-height: 55px;
    text-align: center;
    font-style: normal;
    margin-bottom: 110px;
  }
  .partner-main{
    position: relative;
  }
  .partner-main::before{
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 300px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    z-index: 1;
  }
  .partner-main::after{
    content: "";
    position: absolute;
    right: 0px;
    top: 0px;
    width: 300px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
  .partner-box{
    display: flex;
    margin-bottom: 30px;
    position: relative;
  }
  .partner-first{
    left: 50px;
    .partner-left{
      flex: 0 0 1660px;
      display: flex;
      margin-right: 20px;
      -webkit-animation: scrollToRight-data-v-6a32e916 30s linear infinite;
            animation: scrollToRight-data-v-6a32e916 30s linear infinite;
    }
    .partner-right{
      flex: 0 0 1660px;
      display: flex;
      -webkit-animation: scrollToRight-data-v-6a32e916 30s linear infinite;
            animation: scrollToRight-data-v-6a32e916 30s linear infinite;
    }
  }
  .partner-item{
    flex: 0 0 265px;
    height: 90px;
    line-height: 90px;
    background: #FFFFFF;
    box-shadow: 0px 8px 30px 0px rgba(70,45,0,0.06);
    border-radius: 16px;
    border: 1px solid #EBEBEB;
    margin-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    img{
      display: inline-block;
      width: 60px;
      height: 60px;
      vertical-align: -23px;
      margin-left: 20px;
      margin-right: 7px;
      border-radius: 60px;
    }
  }
  .partner-imgs{
    width: 1178px;
    margin: 0 auto;
    margin-top: 90px;
    display: flex;
    justify-content: space-between;
    .partner-imgsleft{
      width: 372px;
      height: 357px;
      background: linear-gradient( 224deg, #FFF9F6 0%, #FFEBE2 100%), linear-gradient( 320deg, rgba(255,231,101,0) 0%, rgba(255,231,101,0.2) 100%);
      border-radius: 12px;
      position: relative;
      .part-tit{
        color: #6C3D00;
        text-align: center;
        img{
          display: inline-block;
          width: 142px;
          height: 23px;
        }
      }
      .partleft1{
        position: absolute;
        left: 40px;
        top: 100px;
        width: 150px;
        height: 148px;
      }
      .partleft2{
        position: absolute;
        left: 188px;
        top: 126px;
        width: 130px;
        height: 140px;
      }
      .partleft3{
        position: absolute;
        left: 67px;
        top: 283px;
        width: 90px;
        height: 20px;
      }
      .partleft4{
        position: absolute;
        left: 201px;
        top: 288px;
        width: 102px;
        height: 20px;
      }
    }
    .partner-imgsright{
      position: relative;
      width: 764px;
      height: 357px;
      background: #FFFFFF;
      box-shadow: 0px 2px 80px 0px rgba(0,0,0,0.04);
      border-radius: 12px;
      .part-tit{
        color: #7D8187;
        text-align: center;
        img{
          display: inline-block;
          width: 142px;
          height: 23px;
        }
      }
      .h5imgbox{
        display: none;
      }
      .partrt1{
        position: absolute;
        left: 126px;
        top: 150px;
        width: 127px;
        height: 30px;
      }
      .partrt2{
        position: absolute;
        left: 305px;
        top: 149px;
        width: 140px;
        height: 31px;
      }
      .partrt3{
        position: absolute;
        left: 509px;
        top: 148px;
        width: 130px;
        height: 33px;
      }
      .partrt4{
        position: absolute;
        left: 251px;
        top: 221px;
        width: 57px;
        height: 33px;
      }
      .partrt5{
        position: absolute;
        left: 388px;
        top: 223px;
        width: 154px;
        height: 30px;
      }
      .partrt6{
        position: absolute;
        left: 507px;
        top: 221px;
        width: 144px;
        height: 34px;
      }

    }
    .part-tit{
      text-align: center;
      height: 29px;
      font-family: AlimamaShuHeiTi;
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      font-style: normal;
      margin-top: 30px;
    }
  }
}


.logo_wrap .line_even[data-v-6a32e916] {
    width: 3976px;
    -webkit-animation: scrollToRight-data-v-6a32e916 30s linear infinite;
            animation: scrollToRight-data-v-6a32e916 30s linear infinite;
}

.partner-boxs{
  max-width: 1288px;
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .prtner-item{
    flex:1;
    margin-right: 26px;
    height: 410px;
    background-color: #FFFFFF;
    box-shadow: 0px 8px 30px 0px rgba(70,45,0,0.06);
    border-radius: 16px;
    border: 2px solid #FFFFFF;
    padding: 0 30px 0px;
    transition: all 0.4s;
  }
  .prtner-item:nth-child(3){
    margin-right:0px;
  }
  .item-top{
    height: 140px;
    border-bottom: 1px solid rgba(197,141,70,0.5);
    position: relative;
    padding-left: 96px;
    padding-top: 40px;
    transition: all 0.4s;
    img{
      width: 80px;
      height: 80px;
      border-radius: 40px;
      position: absolute;
      left: 0px;
      top: 26px;
      transition: all 0.4s;
    }
    .name{
      font-family: Alibaba-PuHuiTi, Alibaba-PuHuiTi;
      font-weight: normal;
      font-size: 24px;
      color: rgba(0,0,0,0.8);
      line-height: 33px;
      margin-bottom: 6px;
    }
    .titText{
      font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
      font-weight: 400;
      font-size: 16px;
      color: rgba(0,0,0,0.6);
    }
  }
  .item-des{
    padding-top: 30px;
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 400;
    font-size: 16px;
    color: rgba(0,0,0,0.8);
    line-height: 28px;
    background: url('@a/images/home/partbt.png') right bottom no-repeat;
    background-size: 120px 104px;
    min-height: 226px;
  }
  .partner-yellow{
    
    box-shadow: 0px 8px 80px 0px rgba(86,56,0,0.08);
    margin-top: -20px;
    
    // background-color: linear-gradient( 136deg, #FFFAF5 0%, #FFFFFF 100%);
    background: linear-gradient( 136deg, #FFFAF5 0%, #FFFFFF 100%);
    .item-top{
      padding-left: 150px;
      img{
        width: 140px;
        height: 140px;
        border-radius: 40px;
        top: -26px;
      }
      .name{
        color: #FB9716;
      }
    }
    .item-des{
      background: url('@a/images/home/partbty.png') right  bottom no-repeat;
      background-size: 120px 104px;
    }
  }
}

@keyframes scrollToRight-data-v-6a32e916 {
  from {
      -webkit-transform: translate3d(-50%, 0, 0);
              transform: translate3d(-50%, 0, 0);
  }
  to {
      -webkit-transform: translate3d(0%, 0, 0);
              transform: translate3d(0%, 0, 0);
  }
}
@keyframes scrollToRight-data-v-6a32e916 {
  from {
      -webkit-transform: translate3d(-50%, 0, 0);
  }
  to {
      -webkit-transform: translate3d(0%, 0, 0);
  }
}
@media screen and (max-width: 750px) {
    .partner-page {
      width: auto;
      min-width: auto;
      margin: 0 auto;
      overflow: hidden;
      padding-top: 40px;
      padding-bottom: 0px;

      .partner-tit{
        margin-bottom: 0px;
        font-size: 28px;
      }

      .partner-boxs {
        width: auto;
        min-width: auto;
        margin: 0 auto;
        display: block;
        .prtner-item {
          width: auto;
          height: auto;
          padding: 0 20px 10px;
          margin: 30px 20px;
        }
        .item-top .name{
          font-size: 18px;
          color: rgba(0, 0, 0, 0.8);
          line-height: 33px;
          margin-bottom: 6px;
        }
      }

      .partner-imgs{
        width: auto;
        margin: 0 auto;
        margin-top: 60px;
        display: block;
        .partner-imgsleft{
          width: auto;
          height: 357px;
          margin: 0 20px;
          padding-top: 1px;
        }
        .partner-imgsright{
          position: relative;
          width: auto;
          height: 250px;
          margin: 0 20px;
          padding: 1px 10px 20px 20px;
          margin-top: 20px;
          
          .h5imgs{
            display: block;
            display: flex;
          }
          .partrt1{
            position: absolute;
            left: 46px;
            top: 90px;
            width: 98px;
            height: auto;
          }
          .partrt2{
            position: absolute;
            left: 210px;
            top: 89px;
            width: 110px;
            height: auto;
          }
          .partrt3{
            position: absolute;
            left: 46px;
            top: 136px;
            width: 110px;
            height: auto;
          }
          .partrt4{
            position: absolute;
            left: 210px;
            top: 136px;
            width: 46px;
            height: auto;
          }
          .partrt5{
            position: absolute;
            left: 46px;
            top: 190px;
            width: 116px;
            height: auto;
          }
          .partrt6{
            position: absolute;
            left: 210px;
            top: 190px;
            width: 110px;
            height: auto;
          }
          img.partrt{
            display: none
          }
          .h5imgbox{
            display: block;
            padding-top: 20px;
            .h5imgs{
              display: flex;
              justify-content: center;
              padding-bottom: 20px;
              img{
                width: 120px;
              }
              img:nth-child(1){
                margin-right: 30px;
              }
            }
          }
          
        }

      }


    }
    .partner-boxs .item-des{
      padding-bottom: 16px;
    }
    .partner-boxs .partner-yellow{
      box-shadow: 0px 8px 30px 0px rgba(70,45,0,0.06);
      margin-top: -20px;
      background:none;
      .item-top{
        padding-left: 96px;
        img{
          width: 80px;
          height: 80px;
          border-radius: 40px;
          position: absolute;
          left: 0px;
          top: 26px;
        }
        .name{
          color: rgba(0, 0, 0, 0.8);
        }
      }
      .item-des{
        background: url('@a/images/home/partbt.png') right bottom no-repeat;
        background-size: 120px 104px;
        
      }
    }
    .partner-page .partner-imgs .partner-imgsleft .part-tit img{
      height: 20px;
      width: auto;
    }
    .partner-page .partner-imgs .partner-imgsleft .partleft1{
      position: absolute;
      left: 40px;
      top: 100px;
      width: 130px;
      height: auto;
    }
    .partner-page .partner-imgs .partner-imgsleft .partleft2{
      position: absolute;
      left: 171px;
      top: 100px;
      width: 120px;
      height: auto;
    }
    .partner-page .partner-imgs .partner-imgsright .part-tit img{
      height: 20px;
      width: auto;
    }
    
}
</style>
